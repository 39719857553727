import React, { useCallback, useState } from 'react'

import { LayoutMainpage } from "../layouts/layoutmainpage"
import { TopNavMaine } from '../layouts/top-nav-main'
import Otra from '../../src/theme/images/otraico1.png'
import { Stack ,Box, Button, Typography, styled,Badge } from '@mui/material'
import { colorsApp } from '../theme/colors'
import useWindowDimensions from '../hooks/useWindowDimensions '
import WorktimePage from '../component/worktimepage'
import FoterPage from '../layouts/footerPahe'
import { useNavigate } from 'react-router-dom'
 
const Bransh=()=>{
  const { height, width } = useWindowDimensions();
  const navigate=useNavigate()
  const shapeStyles = { bgcolor:colorsApp.red, width: 45, height: 45 ,padding:1,};
const shapeCircleStyles = { borderRadius: '50%' };
const rectangle = <Box component="span" sx={shapeStyles} />;
const circle = (
  <Stack component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} >
    <Typography textAlign={'center'} variant='button' color={colorsApp.white}>
               قريبا
              </Typography>
  </Stack>
);
  const Button = styled('button')(({ theme }) => ({
    backgroundColor: colorsApp.otrabrown2color, // var(--mui-palette-primary-main)
    //color: colorsApp.otrabrownhevy, // var(--mui-palette-primary-contrastText)
    borderRadius:50,
   flexDirection:'row',
   justifyContent:'space-between',
   margin:10,
   height:"50px",
   width:"350px",
   textAlign:'center',
    textShadow:'unset',
   fontWeight:'bolder'
    
  }));

  const navigateTopage=(val)=>{
    window.open(`${val}`, '_blank')
  }

    return(
          
          <LayoutMainpage>
          <TopNavMaine />
          <Box flexDirection={'column'} alignItems={'center'} height={height}>
          <Stack mt={3} alignItems={'center'}>
            <img src={Otra} alt='logo' style={{borderRadius:100, margin:1,width:"200px",height:"200px"}}/>
        </Stack>
          <Box flexDirection={'column'} alignItems={'center'} >
            <Stack mt={3} mb={3}>
              <Typography textAlign={'center'} variant='h3' color={colorsApp.otraColotText}>
                فروع عطرة
              </Typography>
            </Stack>
            <Stack alignItems={'center'} >
              <Button   onClick={()=>navigateTopage("https://maps.app.goo.gl/uQKsX976LvN1HPs78?g_st=com.google.maps.preview.copy") }>
              <Typography textAlign={'center'} variant='button' color={colorsApp.otrabannerTesxt}>
                جادة قباء
              </Typography>
              </Button>
            </Stack>
            <Stack alignItems={'center'} >
              <Button   onClick={()=>navigateTopage("https://maps.app.goo.gl/xSPkHQ8RVwACwhGS6?g_st=com.google.maps.preview.copy")}>
              <Typography textAlign={'center'} variant='button' color={colorsApp.otrabannerTesxt}>
                 بستان عطرة
              </Typography>
              </Button>
            </Stack>
            <Stack alignItems={'center'} >
            <Badge  showZero color="secondary" sx={{position:'absolute',left:10}}>
              {circle}
              </Badge>
              <Button  disabled={true}  onClick={()=>console.log("tt")}>
              <Typography textAlign={'center'} variant='subtitle1' color={colorsApp.otrabannerTesxt}>
                فرع المغيسلة
              </Typography>
               
              </Button>
            </Stack>
          </Box>
          <Stack   width={"100%"}>
        <WorktimePage />
      </Stack>
      <FoterPage />
        </Box>
          </LayoutMainpage>

       
    )
}

export default Bransh;