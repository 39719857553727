import React, { useCallback, useState } from 'react'
import {
    Alert,Container,
    Box,Grid,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
  } from '@mui/material';
import PrimarySearchAppBar from '../component/Navbar';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';
import { TopNav } from '../layouts/top-nav';
import { OverviewLatestOrders } from '../overview/overview-latest-orders';
import { TabeleDetails } from './regestrTabel';
import { TabelCard } from '../component/card';
import TabelService from "../../src/sevice/order.service"
import { useEffect } from 'react';
import TabelShow from './tabelshow';
import { TabeleDetailsUpdate } from './updateTabe;';
import LandPage from './customer/landpage';
import ControlTabel from './controlpanel';
import Orderpanel from './orderpanel';
import styled from '@emotion/styled';
import { SideNav } from '../layouts/side-nav';
import Bookingsystem from './bookingsystem';
import Otrapecnek from './otrapecnek';

 
const Home = () => {
  let navigate =useNavigate()
  const[screen,setScreen]=useState("bookingsystem")
  const [openNav, setOpenNav] = useState(false);
  const { user } = useUserAuth();


  useEffect(()=>{
     if (!user) {
   navigate("/login")
  }
  },[])
  
  const SIDE_NAV_WIDTH = 0;

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});
  
const handelScreen=(value)=>{
  setScreen(value)
}
  return (
    <LayoutRoot>
    <LayoutContainer>
      <TopNav handelScreen={handelScreen} />
      
      {screen==="panel"&&<ControlTabel/>}
      {screen==="bookingsystem"&&<Bookingsystem/>}
      {screen==="orderpanel"&&<Orderpanel/>}
      {screen==="otrapecnek"&&<Otrapecnek/>}
      
       
      </LayoutContainer>
      </LayoutRoot>
        
        
    )
}

export default Home;