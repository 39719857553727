import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  Typography,FormControl,InputAdornment,
  Alert,Modal,ChildModal,InputLabel,Input,
  Stack,Container, SvgIcon,
  Zoom,
  useTheme
} from '@mui/material';
 
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/WhatsApp';
import {AccountCircle,Directions,Email} from '@mui/icons-material';
import { LayoutMainpage } from '../../layouts/layoutmainpage';
import MainePage from './MainePage';
import { TopNavMaine } from '../../layouts/top-nav-main';
import FoterPage  from '../../layouts/footerPahe';
import { useUserAuth} from '../../context/UserAuthContext';
import { db } from '../../config/firbase';
import { getAuth, updateProfile ,updateEmail,verifyBeforeUpdateEmail} from "firebase/auth";
import { colorsApp } from '../../theme/colors';
import { doc, setDoc } from 'firebase/firestore';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { FaWhatsapp } from 'react-icons/fa';


const LandPage=(props)=>{
  const{dilog,setDiloge}=useState(false)
  const [open, setOpen] =useState(false);
  const[username,setname]=useState('')
  const[email,setemail]=useState('')
  const {user}=useUserAuth()
  const [userStatues,setuserStatues]=useState(false)
  const [value, setValue] = useState(0);
  const theme = useTheme();

    useEffect(()=>{
      if (user.isAnonymous==false && user.displayName==null){
        console.log("user not compleate profile ",user.displayName)
        handleOpen()
      }else{
        console.log("user is complete profile ")
       
      }
    },[])

    
  useEffect(()=>{
    auth_start()
    },[])

    const auth_start= async()=>{ 
      if(user.isAnonymous){
          console.log("start auth  isAnonymous",user.uid)
          setuserStatues(false)
      } else if(!user.isAnonymous){
       // //console.log("user is normla",user.accessToken)
        console.log("start auth is not Anonymous",user.uid)
        setuserStatues(true)
         
      }
      
    } 

    const handleOpen = () => {
      setOpen(true);
    
    };
    const handleClose = () => {
      setOpen(false);
    };
    

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

    const updatePrfileBk=async()=>{
      
        const auth = getAuth();
      try {
        await setDoc(doc(db, "users", user.uid), {
            fullName: username,
            phone: user.phoneNumber,
            email:email
            
        }).then(()=>{
          updateProfile(auth.currentUser, {
                displayName: username,photoURL: "https://example.com/jane-q-user/profile.jpg"
              }).then((res) => {
                console.log("name===",res)
                // ...
             }).catch((error) => {
                console.log(error)
                // An error occurred
                // ...
              });
        });
    } catch (e) {
        console.error("Error update profile document: ", e);
    }
    }
    return (
      <>
        <LayoutMainpage>
          <TopNavMaine />

          <MainePage user={userStatues} />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ direction: "rtl" }}
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                right={true}
              >
                بيانات الضيف
              </Typography>
              <FormControl variant="standard">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "23px",
                  }}
                >
                  <AccountCircle
                    sx={{ color: colorsApp.otrabrown2color, mr: 1, my: 0.5 }}
                  />
                  <TextField
                    id="input-with-sx"
                    label="اسم الضيف"
                    variant="standard"
                    onChange={(event) => setname(event.target.value)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: "23px",
                    mb: "25px",
                  }}
                >
                  <Email
                    sx={{ color: colorsApp.otrabrown2color, mr: 1, my: 0.5 }}
                  />
                  <TextField
                    id="input-with-sx"
                    label="الايميل"
                    variant="standard"
                    onChange={(event) => setemail(event.target.value)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "12px",
                    mb: "12px",
                  }}
                >
                  <Button
                    onClick={() => updatePrfileBk()}
                    size="medium"
                    variant="contained"
                    color={"primary"}
                  >
                    حفظ
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </Modal>

          <Stack alignItems={"flex-end"} padding={1}>
            <Fab
              size="large"
              color="success"
              aria-label="add"
              sx={{ alignContent: "center", alignItems: "center" }}
            >
              <a
                href="https://api.whatsapp.com/send?phone=+966556454258"
                target="_blank"
              >
                <AddIcon color="primary" sx={{ fontSize: "37px" }} />
              </a>
            </Fab>
          </Stack>

          <FoterPage />
        </LayoutMainpage>
      </>
    );
}

const style = {
  position:"absolute",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  
  pt: 2,
  px: 4,
  pb: 3,
};

export default LandPage;

// <Box
//       sx={{
//         display: 'flex',
//         flexWrap: 'wrap',
//         '& > :not(style)': {
//           m: 1,
//           width: 128,
//           height: 128,
//         },
//       }}
//     >
//       <Paper elevation={0} />
//       <Paper />
//       <Paper elevation={3} />
//     </Box>




// {tiers.map(tier => (
//   // Enterprise card is full width at sm breakpoint
//   // <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
   
//   // </Grid>
 
//      <Card>
//       <CardHeader
//         title={tier.title}
//         subheader={tier.subheader}
//         titleTypographyProps={{ align: 'center' }}
//         subheaderTypographyProps={{ align: 'center' }}
//         action={tier.title === 'Pro' ? <StarIcon /> : null}
//         //className={classes.cardHeader}
//       />
//       <CardContent>
//         <div >
//           <Typography component="h2" variant="h3" color="textPrimary">
//             ${tier.price}
//           </Typography>
//           <Typography variant="h6" color="textSecondary">
//             /mo
//           </Typography>
//         </div>
//         {tier.description.map(line => (
//           <Typography variant="subtitle1" align="center" key={line}>
//             {line}
//           </Typography>
//         ))}
//       </CardContent>
//       <CardActions  >
//         <Button fullWidth variant={tier.buttonVariant} color="primary">
//           {tier.buttonText}
//         </Button>
//       </CardActions>
//     </Card>

  
// ))}