import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';
import { colorsApp } from '../theme/colors';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
 // const{phone}=props.user
 const{userphone,setUserphoone}=useState(props.user)
  const { logOut} = useUserAuth();

  let navigate =useNavigate()
  

  const handleSignOut = async () => {
    try {
      onClose?.();
      await logOut();
      navigate("/login");
    } catch (error) {
      //console.log(error.message);
    }
  };

  const redirectTocontrolpannel=()=>{
    navigate("/panel")

  }

  //  const handleSignOut = useCallback(
  //   () => {
  //     onClose?.();
  //     await logOut();
  //     navigate("/login");
  //   },
  //   [onClose, auth, router]
  // );

  return (
    <Popover
      anchorEl={anchorEl}
     
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
          backgroundColor:colorsApp.white
        }}
      >
        <Typography variant="caption"  sx={{color:colorsApp.otrabrown1colorlight}}>
          welcome {props.user}
        </Typography>
        
      
      <Divider />
      <MenuList
        
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
         {/* <MenuItem  sx={{color:colorsApp.otraColotText}} onClick={()=>console.log("otra")}>
        عن عطره
        </MenuItem> */}
        <MenuItem  sx={{color:colorsApp.otraColotText}} onClick={
            ()=>navigate("/overviewLatestOrders",{
              state:{
                phone:userphone
              }
            })
            }>
        حجوزاتي
        </MenuItem>
        <MenuItem onClick={handleSignOut} sx={{color:colorsApp.bloodOrange}}>
          تسجيل خروج
        </MenuItem>
       
      </MenuList>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
