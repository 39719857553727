 
 
import { styled } from '@mui/material/styles';
import { colorsApp } from '../theme/colors';
 import useWindowDimensions from '../../src/hooks/useWindowDimensions '

 const {width,height} =useWindowDimensions

const SIDE_NAV_WIDTH=0
const LayoutRoot = styled('div')(({ theme }) => ({
  display:'block',
  flex: '1 1 auto',
  width: width,
  height:height,
  backgroundColor:colorsApp.backgroundimage,
  [theme.breakpoints.up('lg')]: {
    paddingLeft:SIDE_NAV_WIDTH
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: "1",
  flexDirection: 'column',
  backgroundColor:colorsApp.backgroundimage,
  width:width,
});

export const LayoutMainpage =(props) => {
  const { children } = props;
  
   
  return (
     <>
     <LayoutRoot>
        <LayoutContainer>
          {children}
        </LayoutContainer>
      </LayoutRoot>
     </>
      
      
     
  );
};
