

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { Box, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Images from '../theme/images/images';
import { colorsApp } from '../theme/colors';


export default function MainTitle2(props) {
    return (
         <Stack sx={{width:props.width ,justifyContent:'center',overflow:'hidden'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#B0A798" fillOpacity="1" d="M0,128L15,117.3C30,107,60,85,90,85.3C120,85,150,107,180,133.3C210,160,240,192,270,202.7C300,213,330,203,360,181.3C390,160,420,128,450,133.3C480,139,510,181,540,170.7C570,160,600,96,630,64C660,32,690,32,720,74.7C750,117,780,203,810,218.7C840,235,870,181,900,176C930,171,960,213,990,229.3C1020,245,1050,235,1080,213.3C1110,192,1140,160,1170,138.7C1200,117,1230,107,1260,112C1290,117,1320,139,1350,128C1380,117,1410,75,1425,53.3L1440,32L1440,320L1425,320C1410,320,1380,320,1350,320C1320,320,1290,320,1260,320C1230,320,1200,320,1170,320C1140,320,1110,320,1080,320C1050,320,1020,320,990,320C960,320,930,320,900,320C870,320,840,320,810,320C780,320,750,320,720,320C690,320,660,320,630,320C600,320,570,320,540,320C510,320,480,320,450,320C420,320,390,320,360,320C330,320,300,320,270,320C240,320,210,320,180,320C150,320,120,320,90,320C60,320,30,320,15,320L0,320Z"></path></svg>
            <Typography flexWrap={'wrap'} align="center" letterSpacing={.8} color={colorsApp.black} sx={{ m:0,fontFamily: "Cairo", backgroundColor: "#B0A798" ,overflow:'hidden'}} fontSize={"22px"}>
                {props.text}
            </Typography>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#B0A798" fillOpacity="1" d="M0,128L15,117.3C30,107,60,85,90,85.3C120,85,150,107,180,133.3C210,160,240,192,270,202.7C300,213,330,203,360,181.3C390,160,420,128,450,133.3C480,139,510,181,540,170.7C570,160,600,96,630,64C660,32,690,32,720,74.7C750,117,780,203,810,218.7C840,235,870,181,900,176C930,171,960,213,990,229.3C1020,245,1050,235,1080,213.3C1110,192,1140,160,1170,138.7C1200,117,1230,107,1260,112C1290,117,1320,139,1350,128C1380,117,1410,75,1425,53.3L1440,32L1440,0L1425,0C1410,0,1380,0,1350,0C1320,0,1290,0,1260,0C1230,0,1200,0,1170,0C1140,0,1110,0,1080,0C1050,0,1020,0,990,0C960,0,930,0,900,0C870,0,840,0,810,0C780,0,750,0,720,0C690,0,660,0,630,0C600,0,570,0,540,0C510,0,480,0,450,0C420,0,390,0,360,0C330,0,300,0,270,0C240,0,210,0,180,0C150,0,120,0,90,0C60,0,30,0,15,0L0,0Z"></path></svg>

        </Stack>
    )
}