import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,Select,
  Unstable_Grid2 as Grid,
  Typography,
  Alert,IconButton,
  Stack,Container, SvgIcon,indc, ListItem, ListItemButton, ListItemText, InputLabel, RadioGroup, FormControl, FormControlLabel, Radio, FormLabel, List, ListItemIcon, MenuItem, Autocomplete, NativeSelect, ToggleButton
} from '@mui/material';
import {FiArrowLeft, FiArrowUpRight, FiLoader} from 'react-icons/fi'

import StarIcon from '@heroicons/react/24/solid/StarIcon';
import {IconContext} from "react-icons"
import { styled } from '@mui/material/styles';
import TabelService from "../../sevice/order.service"
import { FaCaretSquareLeft  ,FaCaretSquareRight , FaSearchPlus, FaMapPin, FaAccessibleIcon} from "react-icons/fa";
 import { FaAngleRight } from "react-icons/fa";

import ProductCard from './ProductCard';
import HorizontalLinearStepper from './step';
import BasicDateCalendar from './boking';
import otra from '../../theme/images/otraico1.png'
import { colorsApp } from '../../theme/colors';
import { TopNavMaine } from '../../layouts/top-nav-main';
import MainTitle from '../../component/otratext';
import CommentIcon from '@mui/icons-material/Comment';
import FoterPage from '../../layouts/footerPahe';
import WorktimePage from '../../component/worktimepage';
import { useNavigate,Navigate } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import { ConfirmationDialogRaw } from '../../component/dialog';
import { LayoutMainpage } from '../../layouts/layoutmainpage';
import useWindowDimensions from '../../hooks/useWindowDimensions ';


let USERDATA
let userinfo="0554343"

const Productshow=(props)=>{
const[tabel,settabels]=useState([])
const[bookingTabel,setBookingTable]=useState([])
const[screenName,setscreenName]=useState(1)
const[loading,setloding]=useState(true)
const[lodingGest,setlodingGest]=useState(true)
const[stp,setstp]=useState(0)
const[bookingData,setBookingData]=useState([])
const[gestcount,setgestacount]=useState([])
const[select,setselect]=useState(0)
const [open, setOpen] = useState(false);
const [value, setValue] = useState('Dione');
const screenMax=3
const screenMin=0
const[personCount,setpersonCount]=useState(1)
const navigate = useNavigate();
const {user}=useUserAuth()
const { height, width } = useWindowDimensions()

const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    maxHeight:'100%',
    backgroundColor:colorsApp.backgroundPage
  //   [theme.breakpoints.up('lg')]: {
  //     paddingLeft: SIDE_NAV_WIDTH
  //   }
  }));
  
  const LayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
  });

  useEffect(()=>{
    getCardsTabel()
  },[])

  
  useEffect(()=>{
    setlodingGest(true) 
  },[gestcount])

  

  useEffect(()=>{
       
    if(user){
      USERDATA={ 
    email:user.email===null?"":user.email,
    phone:user.phoneNumber===null?"":user.phoneNumber,
    userid:user.uid,
    accsesstoken:user.accessToken
  }
  if(USERDATA.email===""){
    userinfo=USERDATA.phone
  }else{
    userinfo=USERDATA.email
  }
  //console.log("test user from Nav",userinfo)

 
   
    }
    
},[])

  const getCardsTabel=async()=>{
    const data= await TabelService.getalltabel() 
    settabels(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
   setloding(false)
   setscreenName(1)
    
  }

  const get_tabel_booking=(item)=>{
    
    var holderarray=[]
    for (var i = 0; i <= item.gest; i++) {
      if(i>0){
        holderarray[i]={gestcount:i}
      }
      
    }
    // GESCOUNT=holderarray.map(x => ({
    //   gestcount:x.gest
    // }))

    setgestacount(holderarray)


    //console.log("tes ====t booking GESCOUNT ",holderarray)
    setBookingData(item)
    setscreenName(2)
    setstp(1)
  }
 
  const get_time_boking=(data)=>{
    //console.log("boking data ",data)
     
    setBookingData((prev)=>({...prev,...data}))
    console.log("test booking Tabel ",data)
    setscreenName(3)
    setstp(3)
    
  }
  
  
  const handleChangeGestNo = (gest) => {
   // setselect(index)
    setpersonCount(gest)
    //console.log("ges*******t ",gest)

  };
  
  const handleChange = (event) => {
    setpersonCount(event.target.value);
    //console.log("ges*******t ",event.target.value)
  };

  const pickUP_boking=( )=>{
     
    const newData={...bookingData,gest:personCount,phone:userinfo,state:"new"}
    //console.log("boking data finle ",newData)
    //console.log("boking data finle ",personCount)
    navigate("/orderPage",{
      state:{
        data:newData,
        slot:bookingData.slot
      }
    })
    
    // setBookingData((prev)=>({...prev,...gestNo}))
    // //console.log("testbooking tabel ",data)
    // setscreenName("screen3")
    // setstp(3)
    
  }

 const  moveBack=()=>{
  if(screenName===1){
    return alert("tihs start page")
  }
  setscreenName(screenName-1)
  setstp(stp-1)
  }
  
 const  moveFront=()=>{
  if(screenName===3){
    return alert("this is end of pagw")
  }
  setscreenName(screenName+1)
  setstp(stp-+1)
  }
 

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };


    return(
      <LayoutMainpage>
        <Box display={'contents'} sx={{height:height, alignItems:'center',backgroundColor:colorsApp.transparent  ,flexDirection:'row'}}>
          <TopNavMaine/>
          <Stack direction={'row'} sx={{mt:5,justifyContent:'space-around'}}   spacing={1} >
              <FaCaretSquareLeft  size={45} style={{ borderRadius:"8px",marginTop:3}}  color={colorsApp.otraGreenColor}  onClick={()=>moveBack() } />
                <MainTitle width={"306px"}/>
                  <FaCaretSquareRight   size={45} style={{borderRadius:"8px",marginTop:3}} color={colorsApp.otraGreenColor}  onClick={()=>moveFront()} />
                </Stack>
                
                <HorizontalLinearStepper  stp={stp}/>

                {screenName===2 &&<BasicDateCalendar dataHolder={(data)=>get_time_boking(data)}/>}
                {screenName===3 && 
                <Box sx={{ width:"100%",justifyItems:'center' ,marginTop:0,backgroundColor:colorsApp.otrabrown1color}}>
                  <Stack sx={{ backgroundColor:"transparent",padding:1}} flexDirection={'column'}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"baseline"} sx={{backgroundColor:"transparent"}}>
                      <Stack sx={{width:"185px",alignItems:'center',justifyContent:'center',flexDirection:"column",backgroundColor:"#938b83"}} >
                          <Typography  fontSize={15} color={colorsApp.otrasubtext} sx={{backgroundColor:colorsApp.backgroundPage,width:"100%", textAlign:'center',padding:1}}  >عدد الاشخاص</Typography>
                        <Stack flexDirection={"row"} sx={{ alignItems:'baseline',justifyContent:"space-between",width:'77%',backgroundColor:'transparent',}} > 
                            <Stack alignItems={"center"} justifyContent={'center'}>
                              <Typography fontSize={15} lineHeight={2} fontWeight={"400"}  color={"#F8ECE2"} >{personCount}</Typography>
                            </Stack>
                            <Stack alignItems={"center"} justifyContent={'center'}>
                             <FiArrowUpRight size={28} style={{alignItems:'center',justifyContent:"center",marginTop:1}}  color={colorsApp.white}onClick={handleClickListItem} />
                            </Stack >
                        </Stack>
                      </Stack>
                      <Stack  sx={{width:"185px",alignItems:'center',flexDirection:"column",backgroundColor:"#938b83",mb:2}}>
                          <Typography fontSize={15} color={colorsApp.otrasubtext} sx={{backgroundColor:colorsApp.backgroundPage,width:"100%",mb:2,textAlign:'center',padding:1}}  > تاريخ الحجز </Typography>
                          <Typography fontSize={15}  color={"#F8ECE2"}alignSelf={'center'}>{bookingData.bokingdate}</Typography>
                      </Stack>
                    </Stack>
                    
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"baseline"} sx={{backgroundColor:"transparent"}}>
                      <Stack sx={{width:"185px",alignItems:'center',flexDirection:"column",backgroundColor:"#938b83"}}>
                        <Typography fontSize={15} color={colorsApp.otrasubtext}  sx={{backgroundColor:colorsApp.backgroundPage,width:"100%",mb:2,textAlign:'center',padding:1}}  >
                          بداية الحجز
                        </Typography>
                        <Typography fontSize={15}  color={"#F8ECE2"} sx={{width:"100%",margin:0,direction:'rtl',textAlign:'center',padding:.5}}>
                            {bookingData.bokingstarttime} {bookingData.bokingendtime>12?"ليلا":"مساء"}</Typography>
                        
                      </Stack>
                      <Stack sx={{width:"185px",display:'block',alignItems:'center',flexDirection:"column",backgroundColor:"#938b83"}}>
                        <Typography fontSize={15} color={colorsApp.otrasubtext}  sx={{backgroundColor:colorsApp.backgroundPage,width:"100%",mb:2,textAlign:'center',padding:1}} >
                          نهاية الحجز
                        </Typography>
                        <Typography fontSize={15}  color={"#F8ECE2"}  sx={{width:"100%",margin:0,direction:'rtl',textAlign:'center',padding:.5}}
                            >{bookingData.bokingendtime} {bookingData.bokingendtime>12?"ليلا":"مساء"}</Typography>
                      </Stack>
                    </Stack>
                      
                  </Stack>`
                  
                





                  <Stack justifyContent={'space-around'} flexDirection={"column"} sx={{mt:1,mb:1}}>
                      <Button variant="contained"   sx={{mb:1,color:"white",backgroundColor:colorsApp.otraColotText}} size='large' onClick={()=>pickUP_boking()} >تاكيد الحجز</Button>
                      <Button variant="outlined"   sx={{mb:1,color:'black',borderColor:colorsApp.otraColotText}}  size='large' onClick={()=>window.location.reload() } >العودة للقائمة الرئيسيه</Button>
                  </Stack>
                </Box>}
                <Stack alignContent={'center'} margin={0}>
                    {screenName===1 &&<Stack alignItems={'center'}>
                  {tabel.map((item)=>{
                    return(<ProductCard data={item} Bktabel={(item)=>get_tabel_booking(item)}/>)
                  })}
                </Stack>}
                </Stack>

                

                
               

                {loading&&<Box   >
                  <Stack sx={{marginTop:18,marginLeft:1, marginRight:1,flexDirection:'column',alignItems:'center'}}>
                    <img src={`${otra}`} alt='otra'  width={150} height={100}/>
                    <FiLoader size={44} color='green'  className='loading-icon' />
                  </Stack>
                  </Box>}
             
              {!loading&&
              <Stack alignItems={'center'}>
              <WorktimePage/>
              </Stack> }
              <ConfirmationDialogRaw
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                value={value}
                data={gestcount}
                onHandel={(value)=>handleChangeGestNo(value)}
              />
              <FoterPage/>
              </Box>
      </LayoutMainpage>
    )
}


export default Productshow;