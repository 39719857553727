import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/inter';
import LoginPage from './Auth/login';
import SignUpPage from './Auth/regestry';
import {Route,Routes} from "react-router-dom"
import Home  from './pages/home';
import { UserAuthContextProvider } from './context/UserAuthContext';
import ProtectedRoute from './component/protectRoute';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '../src/theme';
import { CssBaseline } from '@mui/material';
import LandPage from './pages/customer/landpage';
import Productshow from './pages/customer/productshow';
import OrderPage from './pages/customer/orderpage';
import { OverviewLatestOrders } from './overview/overview-latest-orders';
import { TabeleDetails } from './pages/regestrTabel';

import Orderpanel from './pages/orderpanel';
import ControlTabel from './pages/controlpanel';
import GestPage from './pages/customer/gestbooking';
import Bookingsystem from './pages/bookingsystem';
import Otrapecnek from './pages/otrapecnek';
import Mainpicknek from './pages/customer/mainpicknec';
import Bransh from './pages/bransh';


function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <UserAuthContextProvider>
      <Routes>
        <Route path='/' element={
        <ProtectedRoute>
          <LandPage/>
        </ProtectedRoute>
        }  />
        <Route path='/login' element={<LoginPage/>}  />
        <Route path='/signup' element={<SignUpPage/>}  />
        <Route path='/landpage' element={<LandPage/>}  />
        <Route path='/productshow' element={<Productshow/>}  />
        <Route path='/orderPage' element={<OrderPage/>}  />
        <Route path='/overviewLatestOrders' element={<OverviewLatestOrders/>}  />
        <Route path='/tabeleDetails' element={<ControlTabel/>}  />
        <Route path='/orderpanel' element={<Orderpanel/>}  />
        <Route path='/panel' element={<Home/>}  />
        <Route path='/gest' element={<GestPage/>}  />
        <Route path='/bookingsystem' element={<Bookingsystem/>}  />
        <Route path='/otrapecnek' element={<Otrapecnek/>}  />
        <Route path='/utrapannel' element={<Home/>}  />
        <Route path='/bookingsystem' element={<Bookingsystem/>}  />
        <Route path='/mainpicknek' element={<Mainpicknek/>}  />
        <Route path='/bransh' element={<Bransh/>}  />
      </Routes>
    </UserAuthContextProvider>
    </CssBaseline>
   </ThemeProvider>
  );
}

export default App;
