import { alpha } from '@mui/material/styles';

const withAlphas = (color) => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.30),
    alpha50: alpha(color.main, 0.50)
  };
};

export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927'
};

export const indigo = withAlphas({
  lightest: '#F5F7FF',
  light: '#EBEEFE',
  main: '#6366F1',
  dark: '#4338CA',
  darkest: '#312E81',
  contrastText: '#FFFFFF'
});

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF'
});

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF',
  Button_progress:"007468",
  Button_back:"B0A798",
  buttn_text:"#fbece2"
});

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF'
});

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF'
});

export const colorsApp = {
  backgroundPage:"#F8ECE2",
  backgrounheader:"#00766a",
  otraMaintext:"#FFFADD",
  otrabannerTesxt:"#000000",
  otraColotText:"#007468",
  otrasubtext:"#B0A798",
  otrasubBannerText:"#007468",
  otraGreenColor:"#00766a",
  otrabrown1color:"#f8ece2",
  otrabrown2color:"#DED4CA",
  otrabrownhevy:"#938B83",
  otrabrown1colorlight:"#B0A798",
  otratextcard:"#f8ece2",
  waved_images:"#DDD4CA",
  Bottom:"#B2926D",
  option_bottom:"#68615A",
  Button_progress:"007468",
  Button_back:"B0A798",
  background: '#1F0808',
  clear: 'rgba(0,0,0,0)',
  facebook: '#3b5998',
  transparent: 'rgba(0,0,0,0)',
  silver: '#F7F7F7',
  steel: '#CCCCCC',
  error: 'rgba(200, 0, 0, 0.8)',
  ricePaper: 'rgba(255,255,255, 0.75)',
  frost: '#D8D8D8',
  cloud: 'rgba(200,200,200, 0.35)',
  windowTint: 'rgba(0, 0, 0, 0.4)',
  panther: '#161616',
  charcoal: '#595959',
  coal: '#2d2d2d',
  bloodOrange: '#fb5f26',
  snow: 'white',
  black: 'black',
  ember: 'rgba(164, 0, 48, 0.5)',
  fire: '#e73536',
  drawer: 'rgba(30, 30, 29, 0.95)',
  eggplant: '#251a34',
  border: '#483F53',
  banner: '#5F3E63',
  text: '#E0D7E5',
  twitter:'#55acee',
  loginBlue:'#0691ce',
  loginGreen:'#4cd964',
  txtgrey:'#8e9396',
  whites:'#E8E8E8',
  greys:'#cccccc',
  darktext:'#6f6f6f',
  shadows: '#b7b7b7',
  lighttxt:'#929597',
  hintblue:'#c9b0c1',
  lightgrey:'#e2e2e2',
  backgrey:'#e6e6e6',
  blacktxt:'#363636',
  skyblue:'#71d4ff',
  txtsky:'#83c8e7',

  //WooCommerce Color
  veryLightGray: "#dcdcdc",
  red: '#EC1F46',
  white: 'white',
  lightGray: '#959595',
  yellow: '#ffc700',
  lightBlack: '#111111',
  gray: '#d8d8d8',
  purple1:"#9A4EAE",
  purple2:"#D399E6",
  purple3:"#875692 ",
  purple4:"#D5BADB",
  ablack: "000000",
  agreen: "214F5E",
  ablue: "342D71",
  aorang: "BA5B4F",
  awhite: "F5F5F5",
  //amina
  //AminabackgroundColor: "#ffff",E5E5E5E5E5E5
  AminabackgroundColor: "#FFFAFA",
  amin1Button1:'#00ABB9',
  AmonaButtontext:'#F5F5F5',
  AminaButtonNew:'#00ABB9',
  textZahry:"#f38193",
  textgray:"#e6e6e6",
  TexTPink:'#f38193',
  Milky:"#fcebd2",
  hederup:'#00ABB9',
  BottomColor:'#00CBB9',
  AminaPinkButton:"#F38193",
  infotextbackground:"#FCEBD2",
  newTextClr:"#000000",
  enabledButton:"#F5F5F5",
  backgroundimage:"#F8ECE2",
  bannerColor:"#F38193",
  rmadytext:"#B7B7B7",
  smolegrayColors:"#585858",
  pinkystack:"#EFEEFF",
  yellowstack:"#FFF8E5",
  greentext:"#00ABB9",
  goldText:"#FCEBD2",
  boarderColor:"#00000026",
  titleColor:"#02A6B3",
  grayButton:"#F1F1F1",


};

