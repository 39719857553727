

import * as React from 'react';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { colorsApp } from '../theme/colors';
import { worktime } from '../pages/customer/worktime';


const WorktimePage=()=>{
    return(
        <Box sx={{backgroundColor:colorsApp.backgroundPage,}} component={'div'} className='bg2' mt={4}> 
          <Stack   alignItems={'center'} flexDirection={'row'}   mt={2} overflow={'hidden'}>
           
            <div style={{ flex: 1, backgroundColor: "#004D40", height: "3px" }} />
            <Typography style={{ margin: "0 10px" }} sx={{fontFamily:"Cairo",fontStretch:'expanded',color:colorsApp.otraColotText}}>ساعات العمل</Typography>
            <div style={{ flex: 1, backgroundColor: "#004D40", height: "3px" }} />
         
        </Stack>
        <Stack  sx={{backgroundColor:"transparent", alignItems:'center',marginTop:1,display:'flex',padding:1 }} >
          {worktime.map((txt)=>{
            return(
              <Stack flexDirection={"row"} alignItems={'baseline'} justifyContent={'space-between'} sx={{borderRadius:100,backgroundColor: txt.color?colorsApp.otrabrown2color:"transparent",width:"302px"}} >
              <Stack flexDirection={'row'}  justifyContent={'center'} sx={{backgroundColor:"transparent" }} width={140} ml={2} mr={2}>
                 
                <Typography sx={{backgroundColor:'transparent',direction:'rtl'}} fontSize={"16px"}  letterSpacing={1.2}  fontFamily={"Cairo"} fontWeight={"700"} 
                     >{txt.end}</Typography> 
                <Typography sx={{backgroundColor:'transparent',}} fontSize={"16px"}  letterSpacing={1.2}  fontFamily={"Cairo"} fontWeight={"700"} 
                    > - </Typography>  
                <Typography sx={{backgroundColor:'transparent', direction:'rtl'}} fontSize={"16px"}  letterSpacing={1.2}  fontFamily={"Cairo"} fontWeight={"700"} 
                    >{txt.start}</Typography>  
                    
              </Stack>
              <Stack flexDirection={'row'} alignContent={"flex-end"}  sx={{ display:"contents"}} width={"180px"} >
                 <Typography fontSize={"16px"} textAlign={'right'} letterSpacing={1.2} padding={1} fontFamily={"Cairo"} fontWeight={"600"} >{txt.day}</Typography>
              </Stack>
            </Stack>
            )
          })}
          </Stack>
        </Box>
      
    )

}

export default WorktimePage;