import React, { useCallback, useState,useEffect } from 'react'
import {
    Alert,Container,
    Box,Grid,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
  } from '@mui/material';
 


import { OverviewOrders } from './orderspanel/overview-all-orders';
import OrderService from '../sevice/request.service';
import TabelService from '../sevice/order.service';
import { OverviewLatestProducts } from './orderspanel/overview-latest-products';

 
const Orderpanel = () => {
  
  const[orders,setOrders]=useState([])
  const[tabels,settabels]=useState([])


  useEffect(()=>{
    getAllorder()
    getCardsTabel()
    
  },[])

  useEffect(()=>{
    console.log("orders com in ", orders)
    
  },[orders])

  const getAllorder=async()=>{
    
    const data= await OrderService.getallorder() 
    setOrders(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
    
  }
  
const getCardsTabel=async()=>{
    const data= await TabelService.getalltabel() 
    settabels(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
    
  }
  
  
  return (
     <>
     <Stack sx={{width:"100%",flexDirection:'row'}} >
       <Typography variant='h4'>جدول حجوز الطاولات </Typography>
        {tabels.length>=1 &&<OverviewLatestProducts sx={{width:"20%",margin:3,borderColor:'black',borderWidth:1}} products={tabels}/>}
        {orders.length>= 1&&<OverviewOrders width={300} sx={{width:"80%",margin:3,borderColor:'black',borderWidth:1}} request={orders}/>}
        
     </Stack>
     
     </>
        
        
    )
}

export default Orderpanel;