import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../config/firbase";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,RecaptchaVerifier,
    GoogleAuthProvider,
    signInWithPopup,signInAnonymously,
    signInWithPhoneNumber
  } from "firebase/auth";
const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState({});
    const [userUId,setUserUid]=useState('')
    const[tabelItemsUpdtVal,settabelItemsUpdt]=useState(false)
    
    function logIn(email, password) {
      return signInWithEmailAndPassword(auth, email, password);
    }
    function signUp(email, password) {
      return createUserWithEmailAndPassword(auth, email, password);
    }
    function logOut() {
      return signOut(auth);
    }
    function googleSignIn() {
      const googleAuthProvider = new GoogleAuthProvider();
      return signInWithPopup(auth, googleAuthProvider);
    }
    function updateTablesItems(val){
      settabelItemsUpdt(val)
      //console.log("start updaet tabels order",val)
    }   
    function Anonymously(){
      return signInAnonymously()
      
    }

    function setUpRecaptchaVerifier(number){
      const recaptchaVerifier= new RecaptchaVerifier(auth,  'recaptcha-container', {
        'size': 'invisible',
      },
         
        // 'expired-callback': () => {
        //   // Response expired. Ask user to solve reCAPTCHA again.
        //   // ...
        //   console.log("expired  Verccation")
        // },
        
        auth  
      );
    recaptchaVerifier.render()
    return signInWithPhoneNumber(auth,number,recaptchaVerifier)
       
    }

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
        if (currentuser) {
          console.log("check Auth", currentuser);
          setUser(currentuser);
      } else {
          signInAnonymously(auth).then((res)=>{
            setUserUid(res)
            console.log("check Auth isAnonymously uid = ", res);
          })
             .catch(error => {               
              setUser(null)
             })
       }
       
        
      });
  
      return () => {
        unsubscribe();
      };
    }, []);

    return<userAuthContext.Provider
        value={{ user,userUId,logIn, signUp, logOut, googleSignIn ,Anonymously,updateTablesItems,tabelItemsUpdtVal,setUser,setUpRecaptchaVerifier}}
        >{children}</userAuthContext.Provider>
}

 
export function useUserAuth() {
  return useContext(userAuthContext);
}