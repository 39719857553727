import { collection,getDoc,getDocs,addDoc,updateDoc,deleteDoc,doc,where,query,onSnapshot } from "firebase/firestore";
import { db } from "../config/firbase";

const SlotsCollectionRef=collection(db,"slots")

class SlotService{
addslots=(newslot)=>{
    return addDoc(SlotsCollectionRef,newslot)
}
UpdateSlot=(id,updateslot)=>{
    const slotsdoc=doc(db,"slots",id)
    return updateDoc(slotsdoc,updateslot)
}
deeletslot=(id)=>{
    const slotsdoc=doc(db,"slots",id)
    return deleteDoc(slotsdoc)
}
getslots=()=>{
    return getDocs(SlotsCollectionRef)
}
getslot=(id)=>{
    const slotsdoc=doc(db,"slots",id)
    return getDoc(slotsdoc)
}

getslotbyDate=( val)=>{
    let items = [];
   // const onSnapshot= getDocs(SlotsCollectionRef)
   //console.log("val=",val)
    const q = query(
        SlotsCollectionRef,
        //  where('owner', '==', currentUserId),
        where('endtime', '==', val) // does not need index
        //  where('score', '<=', 100) // needs index  https://firebase.google.com/docs/firestore/query-data/indexing?authuser=1&hl=en
        // orderBy('score', 'asc'), // be aware of limitations: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
        // limit(1)
      );
      const unsub = onSnapshot(q, (querySnapshot) => {
       //console.log("data",querySnapshot)
       
        //  querySnapshot.forEach((doc) => {
        // //    items.push(doc.data());
        //   return querySnapshot.docs
           
        // });
        return querySnapshot
        
      });

      
       return unsub
         
         
   
    
}

}
export default new SlotService();
