import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  Typography,TextareaAutosize,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TabelService from "../../src/sevice/order.service"

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { Image } from "cloudinary-react"
import { useUserAuth } from "../context/UserAuthContext";
const states = [
  {
    value: true,
    label: 'متاح'
  },
  {
    value: false,
    label: 'غير متاح'
  },

];

const states2 = [
  {
    value: true,
    label: 'متاح'
  },
  {
    value: false,
    label: 'غير متاح'
  },

];

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});



export const TabeleDetailsUpdate = (props) => {
  const [selectedImages, setSelectedImages] = useState([])
  const [imageData, setImageData] = useState(null)
  const[tabelImage,setTabelImage]=useState("")
  const [message, setMessage] = useState({ error: false, msg: "" });
  const[showAlret,setshowealert]=useState(false)
  const [values, setValues] = useState(props.data);
  const{updateTablesItems,tabelItemsUpdtVal}=useUserAuth()
 

  useEffect(()=>{
    setTimeout(()=>{
      setshowealert(false)
    },6000)
   
  },[showAlret])

  useEffect(()=>{
    //console.log("step3===",props)
  },[props])

  const handleChange = useCallback(
    (event) => {
      setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    },
    
    []
  );
 

  const clearInput=()=>{
    setValues({
      tabelname: '',
      tabeldetails: '',
      tabelId: 123,
      price: 0,
      gest: 0,
      duration: 90,
      discountValue: 0,
      discount: false,
      tabelavilble: true,
      condition:''
      
    })
    setTabelImage("")
    updateTablesItems(true)

  }
  const handleSubmit = async (e) => {
      if(values.tabelname===""||values.tabeldetails===""){
        alert("عفو الرجاء التاكد من المدخلات !")
        return;
      }

      const data={
        ...values,
        img:tabelImage
      }
    //console.log("UPDATE from UpdateTabel", data)

    try {
       await TabelService.updatetabet(values.id,values)
      rejectUpdate()
      clearInput()
      setshowealert(true)
      
    } catch (err) {
      //console.log("Erorr from addTabel", err)
      setshowealert(true)
      setMessage({ error: false, msg: "لم يتم اضافة الطاوله" })
    }

  }
   

  const uploadImage = () => {
    const formData = new FormData()
    formData.append("file", selectedImages)
    formData.append("upload_preset", "ml_default")
    // Replace YOUR_UPLOAD_PRESET with your cloudinary upload_preset which looks something like this: sdfmpeitro

    const postImage = async () => {
      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/di6ghy1su/image/upload",
          formData
          // Replace YOUR_CLOUD_NAME with your cloudName which you can find in your Dashboard
        )
        setImageData(response.data)
        setTabelImage(`https://res.cloudinary.com/di6ghy1su/image/upload/v1706993212/${response.data.public_id}`)
        ////console.log('rsponse frome server =', response.data)
      } catch (error) {
        console.error("Erorr from upload image Fuction", error)
      }
    }

    postImage()
    // //console.log("star test props  2 ", selectedImages)
  }


  const rejectUpdate=()=>{
   props.rejectUpdate()
  }


  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader
          subheader="نموذج تحديث طاولة خدمة جديدة"
          title="Tabels"
          color="red"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  helperText="Please specify name tabel"
                  label="اسم الطاوله"
                  name="tabelname"
                  onChange={handleChange}
                  required
                  value={values.tabelname}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="الوصف"
                  name="tabeldetails"
                  onChange={handleChange}
                  required
                  value={values.tabeldetails}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="tabel ID#"
                  name="tabelId"
                  onChange={handleChange}
                  type='number'
                  required
                  value={values.tabelId}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="السعر"
                  name="price"
                  onChange={handleChange}
                  type="number"
                  value={values.price}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="عد الاشخاص"
                  name="gest"
                  onChange={handleChange}
                  required
                  type='number'
                  value={values.gest}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="المدة"
                  name="duration"
                  onChange={handleChange}
                  type='number'
                  value={values.duration}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="الخصم"
                  name="discountValue"
                  onChange={handleChange}
                  type='number'
                  value={values.discountValue }
                />
              </Grid>

              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="الخصم متاح"
                  name="discount"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.discount}
                >
                  {states.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="الطاوله متاح"
                  name="tabelavilble"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.tabelavilble}
                >
                  {states2.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid
                xs={12}
                md={6}
              >
                <TextareaAutosize
                  fullWidth
                  placeholder="الشروط والاحكام"
                  maxRows={3}
                  name="condition"
                  onChange={handleChange}
                  required
                  
                  defaultValue={values.condition}
                />
              </Grid>

              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="تحميل صورة العرض"
                  type='file'
                  name="file"
                  onChange={(e) => setSelectedImages(e.target.files[0])}
                  style={{padding:3}}>
                  </TextField>
              </Grid>

              <Grid
                xs={6}
                md={4}
                alignItems={'baseline'}
                justifyContent={'center'}
              >
                {imageData && (
                  <Image
                  width="100px"
                  heigh="100Px"
                  cloudName="di6ghy1su"
                  publicId={`https://res.cloudinary.com/di6ghy1su/image/upload/v1706993212/${imageData.public_id}`}
                  // Replace YOUR_CLOUD_NAME with your cloudName which you can find in your Dashboard. NOTE: Your publicId link might be different.
                  />
                )}

                <Button component="label" variant="contained"  startIcon={<CloudUploadIcon />}
                  onClick={() => uploadImage()}>
                  Upload files
                  <VisuallyHiddenInput type="button" />
                </Button>
              </Grid>
        </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent:"flex-start",flexDirection:"row" ,  }}>
          <Button variant="contained"
            onClick={ handleSubmit}>
              تحديث الطاوله
          </Button>
          <Button variant="contained"
            onClick={()=> rejectUpdate()}>
              تراجع عن التحديث 
          </Button>
          {message.error && <Typography>{message.msg}</Typography>}
          {showAlret&&<Alert severity="success">تم تحديث طاولة الخدمه في القائمة</Alert>}
        </CardActions>
      </Card>
    </form>
  );
};
