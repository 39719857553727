import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { green, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import {FaAddressBook, FaUserCircle,FaShareAltSquare, FaTimesCircle} from 'react-icons/fa'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { colorsApp } from '../../theme/colors';
import { FiClock,FiUser } from 'react-icons/fi';



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProductCard =(props)=> {
  const{Bktabel}=props
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card  sx={{ maxWidth: 345 ,backgroundColor:colorsApp.otraGreenColor,marginTop:2,border:22,borderBlockColor:colorsApp.border,borderWidth:.2, boxShadow:'revert'}} key={props.data.id}>
      <CardHeader
        
        avatar={
          <Avatar sx={{ bgcolor: colorsApp.otrasubtext }} aria-label="recipe">
            {props.data.tabelId}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon fontSize='48' />
          </IconButton>
        }
        title={props.data.tabelname}
        titleTypographyProps={{ fontSize:25,fontFamily:"Cairo" ,color:colorsApp.otraMaintext}}
        subheader={`مخصص لـ ${props.data.gest} اشخاص`}
        subheaderTypographyProps={{color:colorsApp.otrasubtext,fontSize:18,fontFamily:"Cairo" }}
        sx={{margin:0}}
      />
      <CardMedia
        component="img"
        height="190"
        image={props.data.img}
        alt="Paella dish"
        sx={{padding:1}}
      />
      <CardContent >
      <Typography  color={colorsApp.otrabrown1color} fontFamily={"Cairo"}  component={'article'} style={{direction:'rtl'}}>
          {props.data.tabeldetails}
        </Typography>
        <Stack sx={{flexDirection:'row' ,width:'100%',backgroundColor:'transparent',justifyContent:'space-between',marginTop:2}} >
          <Typography variant='subtitle2' color={colorsApp.otraMaintext}  style={{direction:'rtl'}}>{props.data.price} ريال</Typography>
         
          {props.data.discountValue>1?<Typography variant="caption" color={colorsApp.otraMaintext}>{props.data.discountValue} %</Typography>:
                                       <Typography variant="caption" color={colorsApp.otraMaintext}>-</Typography> }
        </Stack>
        <Button variant="contained"  fullWidth size="medium" sx={{marginTop:3,fontFamily:"cairo",fontWeight:'bold',fontSize:18,}} color={'primary'} 
                onClick={()=>Bktabel(props.data)} >
          احجز الان
        </Button>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites" >
          <FiUser color={colorsApp.otraMaintext}size={30}/>
          <Typography marginLeft={1}  color={colorsApp.otraMaintext} fontSize={18}>{props.data.gest}</Typography>
        </IconButton>
        <IconButton aria-label="share">
          <FiClock   color={colorsApp.otraMaintext} />
          <Typography marginLeft={1}  color={colorsApp.otraMaintext} fontSize={18}>{props.data.duration}</Typography>
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more">
          <ExpandMoreIcon  color="warning" />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph textAlign={"center"} color={"CaptionText"} sx={{fontFamily:"Cairo"}} >الشروط والاحكام:</Typography>
          <Typography   textAlign={"center"} color={colorsApp.Milky} sx={{fontFamily:"Cairo",direction:"rtl",fontSize:18  ,flexWrap:'wrap'}} >
           <pre>{props.data.condition}</pre>
          </Typography>
        
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default ProductCard;