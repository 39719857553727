import { collection,getDoc,getDocs,addDoc,updateDoc,deleteDoc,doc } from "firebase/firestore";
import { db } from "../config/firbase";

const OrdercollectionRef=collection(db,"orders")
class OrderService{
addorder=(neworder)=>{
    return addDoc(OrdercollectionRef,neworder)
}
updateorder=(id,updateorder)=>{
    const orderdoc=doc(db,"orders",id)
    return updateDoc(orderdoc,updateorder)
}
deletorder=(id)=>{
    const orderdoc=doc(db,"orders",id)
    return deleteDoc(orderdoc)
}
getallorder=()=>{
    
    return getDocs(OrdercollectionRef)
}
getorders=(id)=>{
    const orderdoc=doc(db,"orders",id)
    return getDoc(orderdoc)
}
getorderbyvalue=(val)=>{
    const collc=db.collection("orders")
    const query=collc.where("phone", '==', true)
    return query;
}

}
export default new OrderService();
