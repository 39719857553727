import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,Select, 
  Unstable_Grid2 as Grid,MenuItem, 
  Typography,
  Alert,IconButton,
  Stack,Container, SvgIcon,indc, ListItem, ListItemButton, ListItemText, InputLabel, RadioGroup, FormControl, FormControlLabel, Radio, FormLabel, List, ListItemIcon, Autocomplete, NativeSelect
} from '@mui/material';
import { styled } from '@mui/material/styles';
import OrderService from "../../sevice/request.service"
  
import { colorsApp } from '../../theme/colors';
import { TopNavMaine } from '../../layouts/top-nav-main';
import MainTitle from '../../component/otratext';
import MainTitle2 from '../../component/otratext2';
import FoterPage from '../../layouts/footerPahe'; 
import { useLocation, useNavigate} from 'react-router-dom'
import WorktimePage from '../../component/worktimepage';
import { useUserAuth } from '../../context/UserAuthContext';
import { FiLoader } from 'react-icons/fi';
import axios from 'axios';
import moment from 'moment';
import useWindowDimensions from '../../hooks/useWindowDimensions ';
import { LayoutMainpage } from '../../layouts/layoutmainpage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../config/firbase';

var qs = require('qs');

const OrderCollectionRef=collection(db,"orders")
let USERDATA
let userinfo="0554343"

const GestPage=(props)=>{
const[loading,setloding]=useState(false)
const[pikOrder,setPikOrder]=useState(false)
const[gest,setgest]=useState(1)
const[price,setprice]=useState(40)
const[disable,setdisable]=useState(true)
const [value, setValue] =  useState(dayjs(new Date()))
let navigate=useNavigate()
 const location =useLocation()
 const {user}=useUserAuth()
 const {width,height} =useWindowDimensions()


 const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');



 const currencies = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
        value: 5,
        label: 5,
      },
      {
        value: 6,
        label: 6,
      },
      {
        value: 7,
        label: 7,
      },
      {
        value: 8,
        label: 8,
      },
      {
        value: 9,
        label: 9,
      },
      {
        value: 10,
        label: 10,
      },
  ];

  
const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    maxWidth: width,
    maxHeight:height,
    flex: '1 1 auto',
    backgroundColor:colorsApp.backgroundimage,
  //   [theme.breakpoints.up('lg')]: {
  //     paddingLeft: SIDE_NAV_WIDTH
  //   }
  }));
  
  const LayoutContainer = styled('div')({
    width:width,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    backgroundColor:colorsApp.backgroundimage,
     
  });

  useEffect(()=>{
   
     if(pikOrder){
      setTimeout(()=>{
        setloding(false)
        navigate("/landpage")
      },4000)
     }
  },[pikOrder])



useEffect(()=>{
       
    if(user){
      USERDATA={ 
      email:user.email===null?"":user.email,
      phone:user.phoneNumber===null?"":user.phoneNumber,
      userid:user.uid,
      accsesstoken:user.accessToken
  }
  if(USERDATA.email===""){
    userinfo=USERDATA.phone
  }else{
    userinfo=USERDATA.email
  }
  //console.log("test user from Nav",userinfo)

 
   
    }
    console.log("startvalue ,==",disable)
    ///calclute total booleng 
    getSlotByval2()
},[])

 const makeNewOrder=(val)=>{
    //console.log(val)
    setgest(val)
    setprice(val*price)
 }


  const submitOrder=async()=>{
    setloding(true)
    const total= ((price*0.15+Number(price)))
    const orderdata={
        bokingdate:dayjs(value).format("YYYY-MM-DD"),
        bokingendtime:"09",
        bokingstarttime:"06",
        condition:"min cost for one person 40",
        discount:true,
        discountValue:0,
        duration:"180",
        gest:gest,
        img:'',
        phone:Number(userinfo),
        state:"pesron",
        tabelId:"11111",
        tabelavilble:true,
        tabeldetails:"حجز رمضان",
        tabelname:"افطار رمضان",
        total:total
    }
    
    if(orderdata){
      await OrderService.addorder(orderdata).then((res)=>{
        //console.log("order detals",res)
        setPikOrder(true)
        sendMSG(orderdata)
      })
    }
   
   console.log(orderdata) 
    
  } 
  const sendMSG=(orderdata)=>{
    const{phone,tabelname,tabelId,gest,bokingdate,bokingstarttime}=orderdata
    var data = qs.stringify({
      "token": "q7tjlgnzrz4cmtsb",
      "to":phone,
      // "body":`طاوله ${tabelname} رقم ${tabelId} وعدد الاشخاص ${gest} سوف يتم مراجعة الطلب والرد بتاكيد الحجزمع تحيات بستان عطرة`
      "body":` تشكر لكم حجزكم في بستان عطرة  ${moment(bokingdate).format("DD-MM-YY")} الساعة ${bokingstarttime} مساء  
      طاولة  ${tabelname}   رقم# ${tabelId} وعدد الاشخاص ${gest} 
      لتاكيد الحجز الرجاء التحويل الى حساب رقم SA4180000419608016079084
      باسم مقهى عطرة الجادة 
      وارسال ايصال التحويل`  
  });
  
  var config = {
      method: 'post',
      url: 'https://api.ultramsg.com/instance80963/messages/chat',
      headers: {  
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : data
    };
    
  
   const response1= axios(config)
  .then(function (response) {
    //console.log("Test api xxxx",JSON.stringify(response.data));
    //res.status(201).send(response.data)
  })
  .catch(function (error) {
    //console.log(error);
  });

  return response1

  }

  

const getSlotByval2=( )=>{
  const timeNow = dayjs(new Date()).format('YYYY-MM-DD');
   console.log("start get slot from backend,== next day===>",timeNow,"===",userinfo)
   
   const q = query(
    OrderCollectionRef,
     //  where('owner', '==', currentUserId),
     where('bokingdate', '==', timeNow ), // does not need index
     where("phone" ,"==",Number(userinfo)) // needs index  https://firebase.google.com/docs/firestore/query-data/indexing?authuser=1&hl=en
     // orderBy('score', 'asc'), // be aware of limitations: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
     // limit(1)
   );
 
  
   // const unsub = onSnapshot(q, (querySnapshot) => {
   const unsub = onSnapshot(q, (querySnapshot) => {
     const items = [];
     querySnapshot.forEach((doc) => {
       items.push(doc.data());
      // //console.log("start get slot from backend,==",items)
     });
     console.log("start get total orders from backend,==",items.length)
     //const timenow=moment(new Date()) 
    if(items.length >= 14){
      setdisable(true)
      console.log("not aloww",items.length)
    }else{
      setdisable(false)
    }
     
   });
   return () => {
     unsub();
   };
  
   // eslint-disable-next-line
 
 
 
 }
 
 
//const order=location.state.data
    return(
         <LayoutMainpage> 
              <TopNavMaine/>
              <>
              <Box sx={{alignItems:'center',flexDirection:'column',backgroundColor:colorsApp.backgroundPage,display:'flex'}}>
                <MainTitle width={"336px"} margin={5}/>
                <MainTitle2  width={"100%"} text={"حجز فطور رمضان"}/>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack >
                      <DemoContainer
                        components={[
                        'DatePicker',
                        'DateTimePicker',
                        'TimePicker',
                        'DateRangePicker',
                        ]}
                      >
                       <DemoItem  label="تاريخ الحجز">
                       <DatePicker
                          defaultValue={dayjs(new Date())}
                           onChange={(newValue) =>setValue(newValue)}
                          disablePast
                          format='YYYY/MM/DD'
                          views={['year', 'month', 'day']}
                        />
                       </DemoItem>
                     </DemoContainer>
                    </Stack>
                  </LocalizationProvider>
                  </Box>
                        <Stack width={"100%"} sx={{border:'ActiveCaption',alignItems:'center',mt:2,borderTopColor:'ActiveCaption',borderTopWidth:1}}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="العدد"
                                value={gest}
                                
                                
                                defaultValue={1}
                                helperText="الرجاء تحديد عدد الاشخاص"
                                onChange={(val)=>  makeNewOrder(val.target.value) }
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value} >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                        <div style={{marginTop:"12px"}}>
                            <Typography color={colorsApp.border} variant="caption" mt={3} >الحد الادنى للطلب للشخص الواحد 98 ريال</Typography>
                        </div>
                        <Stack alignItems={'center'} sx={{alignItems:'center',backgroundColor:"transparent",mt:7,padding:1}}>
                            {loading&&<FiLoader size={40} className="loading-icon" />}
                        </Stack>
                        {pikOrder ?
                            <Box sx={{ width: '100%', padding: 1, mt: 3 }}>
                                <Stack alignItems="center" marginTop={3} >
                                    <Alert variant="outlined" severity="success">
                                    لقد تم ارسال طلبك وسوف يتم تاكيد الحجز عن طريق الواتس
                                    </Alert>
                                    <Button variant="text" size="large" color="info" sx={{ mt: 2, color: colorsApp.otraColotText }} onClick={() => navigate("/")}>الرجوع الى الصفحه الرئيسيه</Button>
                                </Stack>
                            </Box> :
                            <Box sx={{ width: '391px', mt: 3, mr: 2, ml: 2 }}>

                                <Stack sx={{ width: '100%', mt: 1, mb: 1, flexDirection: 'row', backgroundColor: '#B0A798', borderRadius: "5px", padding: 2, justifyContent: 'space-between' }}>
                                    <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{ textAlign: 'center', ml: 3, mr: 3, lineHeight: "20.71px" }}> {gest}</Typography>
                                    <Typography color={"black"} fontFamily={"Cairo"} fontSize={18} fontWeight={"400"} sx={{ textAlign: 'center', ml: 3, mr: 3, lineHeight: "20.71px" }}>عدد الاشخاص</Typography>

                                </Stack>
                                 

                                <Button variant="contained" disabled={disable} fullWidth size="large" sx={{ backgroundColor: colorsApp.backgrounheader, color: 'black', mt: 1 }}
                                    onClick={() => submitOrder()}>تاكيد الحجز</Button>
                                <Button variant="outlined" color="inherit" fullWidth size="large" sx={{ mt: 1 }}
                                    onClick={() => navigate("/")}>الغاء</Button>

                            </Box>}
                   
              </Box>
              <WorktimePage/>
              </>
              <FoterPage/>
      </LayoutMainpage>
    )
}


export default GestPage;