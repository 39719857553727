import { collection,getDoc,getDocs,addDoc,updateDoc,deleteDoc,doc } from "firebase/firestore";
import { db } from "../config/firbase";

const TablecollectionRef=collection(db,"tabel")
class TabelService{
addtabel=(newtabel)=>{
    return addDoc(TablecollectionRef,newtabel)
}
updatetabet=(id,updatetabel)=>{
    const tabeldoc=doc(db,"tabel",id)
    return updateDoc(tabeldoc,updatetabel)
}
delettabel=(id)=>{
    const tabeldoc=doc(db,"tabel",id)
    return deleteDoc(tabeldoc)
}
getalltabel=()=>{
    return getDocs(TablecollectionRef)
}
gettabel=(id)=>{
    const tabeldoc=doc(db,"tabel",id)
    return getDoc(tabeldoc)
}

}
export default new TabelService();
