import { common } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { colorsApp, error, indigo, info, neutral, success, warning } from './colors';
import { BorderColor } from '@mui/icons-material';
import { color } from '@cloudinary/url-gen/qualifiers/background';
 
export function createPalette() {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12)
    },
    background: {
      default: common.white,
      paper: common.white
    },
    
    backgroundPage:{
      maine:colorsApp.backgroundPage
    },
    progrrs:colorsApp.Button_progress,
    divider: '#F2F4F7',
    error,
    info,
    addicon:{
    
        main:colorsApp.white
    
    },
    
    icon:colorsApp.otraGreenColor,
    mode: 'light',
    neutral,
    primary:{
      main: colorsApp.otraMaintext
    },
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      main:colorsApp.otraColotText
    },
    warning
  };
}
