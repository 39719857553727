// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getAnalytics} from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCg7TkDtD4z73nypRLE5t1S3FRZ3AXCfpU",
  authDomain: "otraproj084.firebaseapp.com",
  projectId: "otraproj084",
  storageBucket: "otraproj084.appspot.com",
  messagingSenderId: "92680147280",
  appId: "1:92680147280:web:4848c213af62ce6f729c55",
  measurementId: "G-ZSTWWRZEY4"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth=getAuth(app)
export const db=getFirestore(app)

 