export const worktime=[
    {
        day:"الاثنين",
        start:"6 مساء",
        end:"2 صباحا",
        color:false
    },
    {
        day:"الثلاثاء",
        start:"6 مساء",
        end:"2 صباحا",
         color:false
    },
    {
        day:"الاريعاء",
        start:"6 مساء",
        end:"2 صباحا",
         color:false
    }, {
        day:"الخميس",
        start:"6 مساء",
        end:"2 صباحا",
         color:false
    }
    , {
        day:"الجمعة",
        start:"6 مساء",
        end:"2 صباحا",
         color:false
    },
    {
        day:"السبت",
        start:"6 مساء",
        end:"2 صباحا",
         color:false
    },
    {
        day:"الاحد",
        start:"6 مساء",
        end:"2 صباحا",
         color:false
    }
]