import React, { useCallback, useState,useEffect } from 'react'
import {
    Alert,Container,
    Box,Grid,
    Stack,
    Typography
  } from '@mui/material';
import { TabelCard } from '../component/card';
import { useUserAuth } from "../context/UserAuthContext";
import TabelService from "../../src/sevice/order.service"

const TabelShow=(props)=>{
    const[tabel,settabels]=useState([])
    //get value to update tabels from authcontext 
    const{tabelItemsUpdtVal,updateTablesItems}=useUserAuth()


useEffect(()=>{
    getCardsTabel()
    //console.log("step4===","data=",props)
    
  },[])


  useEffect(()=>{
   
    if(tabelItemsUpdtVal){
      //console.log("get value  Response to update tabels++",tabelItemsUpdtVal)
      getCardsTabel()
    }
  },[tabelItemsUpdtVal])


  
const getCardsTabel=async()=>{
    const data= await TabelService.getalltabel() 
    settabels(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
    updateTablesItems(false)
    //console.log("GET TABEL WAS DONE ")
  }
  const Delettabel=async(id)=>{
    //console.log("Delet item Start => ",id)
    await TabelService.delettabel(id)
    updateTablesItems(true)
  }
  const updateTabelStatuse=async(id)=>{
    await TabelService.updatetabet(id)


  } 
  const changeScreen=(val,data)=>{
    //change from add tabel to update tabel form 
    //console.log("step1===","val is",val,"data=",data)
    props.changeScreenValue(val,data)
  }

return(
     
        <Stack spacing={2} direction={{sx:"column",md:'row'}} flexWrap={'wrap'} justifyContent={'center'}  marginTop={2} marginBottom={2}>
       {tabel.map((doc,index)=>{
              return(
                <Stack justifyContent={'space-around'} >
                  <TabelCard company={doc} delfunction={(val)=>Delettabel(val)} key={doc.id} getTabelupdate={(val,data)=>changeScreen(val,data)}/>
                </Stack>
                   
               
              
               )
            })}
            </Stack>
   

)
   
  }
  export default TabelShow;