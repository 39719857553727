import PropTypes from 'prop-types';
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ClockIcon from '@heroicons/react/24/solid/ClockIcon';
import { Avatar, Box, Card, CardContent,CardMedia,CardActions, Divider,Button, Stack, SvgIcon, Typography, createTheme,Grid  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
 
 
 
export const TabelCard = (props) => {
  const { company,delfunction,getTabelupdate } = props;
  const imageD=require('../../src/theme/images/tbl.jpg')
  return (
  //   <Box justifyContent={'center'}>
  // <Card sx={{ maxWidth:{xs:300,sm:200,md:345},maxHeight:{xs:300,sm:200,md:345},backgroundColor:"whitesmoke",padding:2,
  //           borderColor:'black',borderBlockWidth:1}}
  //           elevation={12}  
       
  //            key={company.id}>
  //   <CardMedia
  //     sx={{height:{xs:88,sm:3,md:88},width:{xs:88,sm:3,md:400},marginLeft:{sx:1,md:13},marginRight:1,backgroundColor:'aqua'}}
  //    // style={{alignSelf:"center", height: 100,width:100,resize:'block'}}
  //     image={company.img}
  //     title={company.tabelname}
  //   />
  //   <CardContent>
  //     <Typography gutterBottom variant='caption' component="h1" textAlign={"center"}  fontFamily={'monospace'} color={"MenuText"}  >
  //      {company.tabelname}
  //     </Typography>
  //     <Typography variant="subtitle1"  component={'animateMotion'} fontSize={12}  >
  //       {company.tabeldetails}
  //     </Typography>
  //   </CardContent>
  //   <CardActions>
  //       <Stack direction="row" spacing={2}>
  //         <Button variant="outlined" startIcon={<DeleteIcon />}>
  //           Delete
  //         </Button>
  //         <Button variant="contained" endIcon={<SendIcon />}>
  //           Send
  //         </Button>
  //       </Stack>
  //   </CardActions>
  //     </Card>
  //   </Box>
   <Card sx={{ maxWidth: 345,margin:2 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={company.img}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {company.tabelname}
        </Typography>
        <Typography variant="body2" textOverflow={"ellipsis"}  overflow={'hidden'} whiteSpace={'nowrap'} color="text.secondary">
          {company.tabeldetails}
        </Typography>
      </CardContent>
      <CardActions sx={{padding:2,justifyContent:'center',backgroundColor:company.discount==="true"?"ActiveCaption":"rebeccapurple"}}>
        <Box flexDirection={"column"} width={"100%"} >
          <Stack flexDirection={'row'} mb={4} >
            <Typography marginLeft={10} color={'white'}>
               السعر {company.price}
            </Typography>
            <Typography marginLeft={10} color={'white'}>
              الخصم {company.discountValue<1 ? "لايوجد":company.discountValue} 
            </Typography>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={"space-between"}>
            <Button size="small" variant="outlined" color="primary" onClick={()=>getTabelupdate(true,company)}>تعديل</Button>
            <Button size="small" variant="outlined" color="error" onClick={()=>delfunction(company.id)}>حذف</Button>
          </Stack>

        </Box>
        
      </CardActions>
    </Card>
  
  );
};

TabelCard.propTypes = {
  company: PropTypes.object.isRequired
};



// <SvgIcon
// color="action"
// fontSize="small"
// >
// <ClockIcon />
// </SvgIcon>

// <Typography
// color="text.secondary"
// display="inline"
// variant="body2"
// >
// Updated 2hr ago
// </Typography>