import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Select,
  Unstable_Grid2 as Grid,
  MenuItem,
  Typography,
  Alert,
  IconButton,
  Stack,
  Container,
  SvgIcon,
  indc,
  ListItem,
  ListItemButton,
  ListItemText,
  InputLabel,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  List,
  ListItemIcon,
  Autocomplete,
  NativeSelect,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import OrderService from "../sevice/request.service";

import { colorsApp } from "../theme/colors";
import { TopNavMaine } from "../layouts/top-nav-main";
import MainTitle from "../component/otratext";
import MainTitle2 from "../component/otratext2";
import FoterPage from "../layouts/footerPahe";
import { useLocation, useNavigate } from "react-router-dom";
import WorktimePage from "../component/worktimepage";
import { useUserAuth } from "../context/UserAuthContext";
import { FiLoader } from "react-icons/fi";
import axios from "axios";
import moment from "moment";
import useWindowDimensions from "../hooks/useWindowDimensions ";
import { LayoutMainpage } from "../layouts/layoutmainpage";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { doc, writeBatch } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../config/firbase';

import Checkbox from "@mui/material/Checkbox";

const Otrapecnek = (props) => {
  const [loading, setloding] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserAuth();
  const { width, height } = useWindowDimensions();
  const [value, setValue] = useState(dayjs(new Date()));
  const [slottime, setslottime] = useState("");
  const [shift, setshift] = useState("مساء");
  const [slotcount, setslotcount] = useState(0);
  const [slotdate, setslotdate] = useState("");
  const [slot, setSlot] = useState([]);
  const [startminuet, setstartminuet] = useState(0);
  const [starttime, setstarttime] = useState(8);
  const [endminute, setendminute] = useState(0);
  const [endtime, setendtime] = useState(0);
  const [enableTime, disableTime] = useState(true);
  const [duration, setduration] = useState(36000);

  const [tabelid, settabelid] = useState("999");
  const [tabelName, settabelName] = useState("جلسة عطرة");

  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");
  const todayStartOfTheDay = today.startOf("day");

  var qs = require("qs");

  const sendMSG = (orderdata) => {
    const { phone, tabelname, tabelId, gest, bokingdate, bokingstarttime } =
      orderdata;
    var data = qs.stringify({
      token: "q7tjlgnzrz4cmtsb",
      to: phone,
      // "body":`طاوله ${tabelname} رقم ${tabelId} وعدد الاشخاص ${gest} سوف يتم مراجعة الطلب والرد بتاكيد الحجزمع تحيات بستان عطرة`
      body: ` تشكر لكم حجزكم في بستان عطرة  ${moment(bokingdate).format(
        "DD-MM-YY"
      )} الساعة ${bokingstarttime} مساء  
      طاولة  ${tabelname}   رقم# ${tabelId} وعدد الاشخاص ${gest} 
      لتاكيد الحجز الرجاء التحويل الى حساب رقم SA4180000419608016079084
      باسم مقهى عطرة الجادة 
      وارسال ايصال التحويل`,
    });

    var config = {
      method: "post",
      url: "https://api.ultramsg.com/instance80963/messages/chat",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    const response1 = axios(config)
      .then(function (response) {
        //console.log("Test api xxxx",JSON.stringify(response.data));
        //res.status(201).send(response.data)
      })
      .catch(function (error) {
        //console.log(error);
      });

    return response1;
  };

  useEffect(() => {
    //action claender
    const slotDate = dayjs(value).format("YYYY-MM-DD");
    setslotdate(slotDate);
    // console.log("change date",slotDate) //2024-03-21
  }, [value]);

  const statusMap = {
    صباحا: "صباحا",
    مساء: "مساء",
    ليلا: "ليلا",
  };
  const filter_Time = (item) => {
    const newarr = slot.filter((xitem) => xitem.starttime !== item.starttime);

    setSlot(newarr);
  };

  const handelTime1 = (data) => {
    setstarttime(data.$H);
    setstartminuet(data.$m);
    setslottime(data.$d);
  };
  const handelTime2 = (data) => {
    setendtime(data.$H);
    setendminute(data.$m);
  };
  const addSingleSlot = () => {
    const data = {
      starttime: starttime,
      startminuet: startminuet,
      endtime: endtime,
      endminute: endminute,
      duration: duration,
      shift: shift,
      slotcount: slotcount,
      tabelid: tabelid,
      slotdate: slotdate,
      slottime: slottime,
    };
    slot.push({
      starttime: starttime,
      startminuet: startminuet,
      endtime: endtime,
      endminute: endminute,
      duration: duration,
      shift: shift,
      slotcount: slotcount,
      tabelid: tabelid,
      slotdate: slotdate,
      slottime: slottime,
    });
    // console.log(data)
    // setSlot([
    //     slot,
    //     {...data}
    // ])
  };
  const uploadSlot=()=>{
    const batch =writeBatch(db)
    
    slot.forEach((item,indx)=>{ 
      const nyRef=doc(db,"slots2",`NYS1${uuidv4()}`)
      batch.set(nyRef,item)
      
    })
    batch.commit().then((res)=>{
      //console.log("was done ",res)
      disableTime(false)
      setSlot([])
      alert("تمت الاضافه بنجاح")
    }).catch(alert( "لم تتم الاضافه بشكل صحيح"))
}
  return (
    <Grid
      container
      columns={{ xs: 3, lg: 3 }}
      spacing={3}
      sx={{ backgroundColor: colorsApp.white }}
      //justifyContent='space-around'
    >
      <Box
        width={200}
        height={120}
        flexDirection={"column"}
        alignContent={"center"}
        bgcolor={colorsApp.ricePaper}
        display={"block"}
        borderRadius={2}
        borderColor={colorsApp.text}
        border={0.3}
        mt={4}
        ml={7}
        mr={7}
      >
        <FormControl sx={{ alignItems: "center", flexDirection: "row" }}>
          <Checkbox
            color="secondary"
            size="large"
            onChange={() => disableTime(!enableTime)}
          />
          <Typography variant="h6" color={colorsApp.otrabrownhevy}>
            جلسة عطره العامه
          </Typography>
        </FormControl>
      </Box>

      <Box
        spacing={2}
        mt={4}
        sx={{
          padding: 1,
          backgroundColor: colorsApp.ricePaper,
          border: 0.5,
          borderRadius: 3,
          borderColor: "black",
          height: "480px",
        }}
      >
        <Box sx={{ flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              sx={{ marginTop: 2, padding: 1 }}
              components={[
                "DatePicker",
                "DateTimePicker",
                "TimePicker",
                "DateRangePicker",
              ]}
            >
              <DemoItem label="تاريخ الحجز">
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  onChange={(newValue) => setValue(newValue)}
                  disablePast
                  format="YYYY/MM/DD"
                  views={["year", "month", "day"]}
                />
              </DemoItem>
            </DemoContainer>
            <DemoItem label="start Time">
              <TimePicker
                defaultValue={todayStartOfTheDay}
                onChange={(time) => handelTime1(time)}
              />
            </DemoItem>
            <DemoItem label="End of time">
              <TimePicker
                defaultValue={todayStartOfTheDay}
                onChange={(time) => handelTime2(time)}
              />
            </DemoItem>
          </LocalizationProvider>
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControl sx={{ alignItems: "center", mt: 2 }}>
            <InputLabel
              variant="standard"
              size="normal"
              htmlFor="uncontrolled-native"
            >
              الفتره
            </InputLabel>
            <NativeSelect
              defaultValue={"مساء"}
              onChange={(val) => setshift(val.target.value)}
              inputProps={{
                name: "age",
                id: "uncontrolled-native",
              }}
            >
              <option value={"صباحا"}>صباحا</option>
              <option value={"مساء"}>مساء</option>
              <option value={"ليلا"}>ليلا</option>
            </NativeSelect>
          </FormControl>
        </Box>

        <Stack
          sx={{
            mt: 3,
            mb: 2,
            padding: 1,
            borderWidth: 1,
            borderColor: "yellow",
          }}
        >
          <Button
            disabled={enableTime}
            variant="contained"
            size="large"
            color="success"
            onClick={() => addSingleSlot()}
          >
            <Typography>اضافة الوقت</Typography>
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          backgroundColor: colorsApp.ricePaper,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-around",
          marginTop: 4,
          marginLeft:7,
          border:1,
          borderColor:colorsApp.text,
          width:"400px"
        }}
      >
        <Stack alignItems={"center"} mb={2} bgcolor={colorsApp.otrabrown2color}>
          <Typography
            variant="caption"
            color={"InfoText"}
            lineHeight={"2.4"}
            fontFamily={"Cairo"}
          >
            عرض شرائح اوقات الحجز
          </Typography>
        </Stack>

        {enableTime ? (
          <Stack alignItems={"center"}>
            <Stack alignItems={"center"}>
              <Typography
                variant="caption"
                color={colorsApp.error}
                fontFamily={"Cairo"}
              >
                حدد اسم الطاوله لاكمال التفاصيل
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack flexDirection={"row"} justifyContent={"center"} mt={2} mb={2}>
            <Typography
              variant="h4"
              color={colorsApp.newTextClr}
              fontFamily={"Cairo"}
            >
              {tabelName}
            </Typography>
            <Typography
              variant="h4"
              color={colorsApp.newTextClr}
              fontFamily={"Cairo"}
            >
              {" "}
              # {tabelid}
            </Typography>
          </Stack>
        )}
        <Stack
          sx={{
            height: 100,
            width: 380,
            padding: 1,
            borderWidth: 1,
            borderColor: "green",
          }}
        >
          {slot.length >= 1 ? (
            <Stack
              sx={{ backgroundColor: "transparent", alignItems: "center" }}
            >
              {slot
                .sort((a, b) =>
                  a.starttime > b.endtime ? 1 : b.endtime > a.starttime ? -1 : 0
                )
                .map((x, index) => {
                  return (
                    <Stack
                      key={index}
                      elevation={3}
                      sx={{
                        backgroundColor: "#6b625b",
                        alignItems: "center",
                        padding: 1,
                        marginTop: 1,
                        boxShadow: "initial",
                        width: 240,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="text"
                        color="error"
                        onClick={() => filter_Time(x)}
                      >
                        x
                      </Button>
                      <Button variant="text" sx={{ color: "white" }}>
                        {" "}
                        من {x.starttime}:{x.startminuet} الى {x.endtime}:
                        {x.endminute} {statusMap[x.shift]}
                      </Button>
                    </Stack>
                  );
                })}
            </Stack>
          ) : (
            <Stack alignItems={"center"} padding={1} mt={8}>
              <Typography
                variant="caption"
                color={"InfoText"}
                fontFamily={"Cairo"}
              >
                {" "}
                لاتوجد اوقات مدخله
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
      <Box  mt={4} height={"220px"} display={'flex'} bgcolor={colorsApp.ricePaper} flexDirection={'column'}>
                <Stack bgcolor={colorsApp.otrabrown2color} alignItems={'center'}>
                <Typography variant='h6' lineHeight={2.5} alignItems={'center'}>التاكيد النهائي</Typography>
                </Stack>
                <Stack mt={3} mb={3} padding={1} flexDirection={'column'} height={'200px'}  justifyContent={'space-between'}>
                    <Button disabled={enableTime} variant="contained" size='large' color="success"  onClick={()=>uploadSlot()}>
                        <Typography variant='body2' textAlign={'center'}>تاكيد اوقات الحجز</Typography>
                    </Button>
                    <Button disabled={enableTime} variant='outlined' size='large' color='warning' onClick={()=> window.location.reload()}>
                                <Typography>الغاء عملية اضافة اوقات الحجز</Typography>
                    </Button>
                </Stack>
            </Box>
    </Grid>
  );
};

export default Otrapecnek;
