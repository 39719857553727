import React, { useCallback, useState } from 'react'
import {
    Alert, Container,
    Box, Grid,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    NativeSelect
} from '@mui/material';
import PrimarySearchAppBar from '../component/Navbar';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';
import { TopNav } from '../layouts/top-nav';
import { OverviewLatestOrders } from '../overview/overview-latest-orders';
import { TabeleDetails } from './regestrTabel';
import { TabelCard } from '../component/card';

import { useEffect } from 'react';
import TabelShow from './tabelshow';
import { TabeleDetailsUpdate } from './updateTabe;';
import LandPage from './customer/landpage';
import ControlTabel from './controlpanel';
import Orderpanel from './orderpanel';
import styled from '@emotion/styled';
import { LayoutMainpage } from '../layouts/layoutmainpage';
import { OverviewLatestProducts } from './orderspanel/overview-latest-products';
import OrderService from '../sevice/request.service';
import TabelService from '../sevice/order.service';
import { DateCalendar, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { colorsApp } from '../theme/colors';
import { doc, writeBatch } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../config/firbase';
const Bookingsystem = () => {
    let navigate = useNavigate()
    const [screen, setScreen] = useState(false)
    const [orders, setOrders] = useState([])
    const[enableTime,disableTime]=useState(true)
    const [tabels, settabels] = useState([])
    const [value, setValue] = React.useState(dayjs(new Date()));
   
    const[duration,setduration]=useState(36000)
    const[endminute,setendminute]=useState(0)
    const[endtime,setendtime]=useState(0)
    const[shift,setshift]=useState("مساء")
    const[slotcount,setslotcount]=useState(0)
    const[slotdate,setslotdate]=useState('')
    const[startminuet,setstartminuet]=useState(0)
    const[starttime,setstarttime]=useState(8)
    const[tabelid,settabelid]=useState('')
    const[tabelName,settabelName]=useState('')
    const[slottime,setslottime]=useState('')
    const[slot,setSlot]=useState([])
    const { user } = useUserAuth();

    const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');

    useEffect(() => {
        if (!user) {
            navigate("/login")
        }
    }, [])

    
React.useEffect(()=>{ 
    //action claender
    const slotDate=dayjs(value).format("YYYY-MM-DD")
    setslotdate(slotDate)
    // console.log("change date",slotDate) //2024-03-21
   },[value])

   const handelTime1=(data)=>{
        
        setstarttime(data.$H)
        setstartminuet(data.$m)
        setslottime(data.$d)
   }
   const handelTime2=(data)=>{
    
    setendtime(data.$H)
    setendminute(data.$m)
}

useEffect(() => {
  // console.log("new slot",slot)
   
}, [slot])

    useEffect(() => {
        getAllorder()
        getCardsTabel()
    }, [])


    const getAllorder = async () => {

        const data = await OrderService.getallorder()
        setOrders(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }

    const getCardsTabel = async () => {
        const data = await TabelService.getalltabel()
        settabels(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }
    
    const handelTabel=(val,name)=>{
       // console.log("test vale",val)
        settabelid(val)
        settabelName(name)
        disableTime(false)
    }
    const addSingleSlot=()=>{
        const data={
         starttime: starttime, 
        startminuet: startminuet,
        endtime: endtime,
        endminute:endminute,
        duration:duration,
        shift:shift,
        slotcount:slotcount,
        tabelid:tabelid,
        slotdate:slotdate,
         slottime:slottime
        }
        slot.push({
            starttime: starttime, 
            startminuet: startminuet,
            endtime: endtime,
            endminute:endminute,
            duration:duration,
            shift:shift,
            slotcount:slotcount,
            tabelid:tabelid,
            slotdate:slotdate,
            slottime:slottime
        })
       // console.log(data)
        // setSlot([
        //     slot,
        //     {...data}
        // ])
    }
const uploadSlot=()=>{
    const batch =writeBatch(db)
    
    slot.forEach((item,indx)=>{ 
      const nyRef=doc(db,"slots",`NYS1${uuidv4()}`)
      batch.set(nyRef,item)
      
    })
    batch.commit().then((res)=>{
      //console.log("was done ",res)
      disableTime(false)
      setSlot([])
      alert("تمت الاضافه بنجاح")
    }).catch(alert( "لم تتم الاضافه بشكل صحيح"))
}

const statusMap = {
    صباحا: 'صباحا',
    مساء: 'مساء',
    ليلا: 'ليلا'
  };

  const filter_Time=(item)=>{

    const newarr=slot.filter((xitem)=> xitem.starttime  !== item.starttime )
    
    setSlot(newarr)
  }

    return (
            <Box sx={{ flexGrow: 1 ,alignItems:'stretch'}}>
            <Grid  container
                columns={{ xs: 1, lg: 3 }}
                spacing={1}
                 
                sx={{backgroundColor:colorsApp.backgroundPage}}
                justifyContent='space-around'>
            <Stack>
                <Stack sx={{width:'400px',marginTop:2, borderWidth: 1, borderColor: "ActiveCaption" }}>
                    <OverviewLatestProducts handelItem={(val,name)=>handelTabel(val,name)} sx={{ width: "100%", margin: 3, borderColor: 'black', borderWidth: 1 }} products={tabels} />
                </Stack>

            </Stack>
           
            <Box spacing={2}mt={4}  sx={{ alignItems:'baseline',padding:1 ,backgroundColor:colorsApp.ricePaper,borderWidth:1,borderColor:'black',height:"450px"}}>
                <Box  sx={{  flexDirection: 'column' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer 
                        sx={{marginTop:2,padding:1 }}
                      components={[
                        'DatePicker',
                        'DateTimePicker',
                        'TimePicker',
                        'DateRangePicker',
                        ]}
                      >
                       <DemoItem label="تاريخ الحجز">
                       <DatePicker
                       
                          defaultValue={dayjs(new Date())}
                           onChange={(newValue) =>setValue(newValue)}
                          disablePast
                          format='YYYY/MM/DD'
                          views={['year', 'month', 'day']}
                        />
                       </DemoItem>
                     </DemoContainer>
                     <DemoItem label="start Time">
                        <TimePicker     defaultValue={todayStartOfTheDay}    onChange={(time)=>handelTime1(time)} />
                    </DemoItem>
                    <DemoItem label="End of time">
                        <TimePicker  defaultValue={todayStartOfTheDay}  onChange={(time)=>handelTime2(time)} />
                    </DemoItem>
                     
                  </LocalizationProvider>
                </Box>
                <Box sx={{mt:2 }}>
                        <FormControl sx={{alignItems:'center',mt:2}}>
                            <InputLabel   variant='standard' size='normal'  htmlFor="uncontrolled-native">
                               الفتره
                            </InputLabel>
                            <NativeSelect
                            defaultValue={"مساء"}
                            onChange={(val)=>setshift(val.target.value)}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',
                            }}
                            >
                            <option value={"صباحا"}>صباحا</option>
                            <option value={"مساء"}>مساء</option>
                            <option value={"ليلا"}>ليلا</option>
                            </NativeSelect>
                        </FormControl>
            </Box>

                <Stack sx={{ mt:3,mb:2, padding: 1, borderWidth: 1, borderColor: "yellow" }}>
                            <Button disabled={enableTime} variant="contained" size='large' color="success" onClick={()=>addSingleSlot()}>
                                <Typography>اضافة الوقت</Typography>
                            </Button>
                            
                </Stack>



               

            </Box>

            <Box  sx={{backgroundColor:colorsApp.ricePaper,alignItems:'center',flexDirection:'column',justifyContent:'space-around',marginTop:4, }}>
                <Stack alignItems={'center'}  mb={2} bgcolor={colorsApp.otrabrown2color}> 
                <Typography variant='caption' color={'InfoText'}  lineHeight={'2.4'}fontFamily={'Cairo'}>عرض شائح اوقات الحجز</Typography>
                </Stack>
                
                {enableTime?
                <Stack alignItems={'center'}>
                    <Stack alignItems={'center'}>
                    <Typography variant='caption' color={colorsApp.error} fontFamily={'Cairo'}>حدد اسم الطاوله لاكمال التفاصيل</Typography>
                </Stack>
                </Stack>:
                <Stack flexDirection={'row'} justifyContent={'center'} mt={2} mb={2}>
                        <Typography variant='h4' color={colorsApp.newTextClr} fontFamily={'Cairo'}>{tabelName}</Typography>
                        <Typography variant='h4' color={colorsApp.newTextClr} fontFamily={'Cairo'}> # {tabelid}</Typography>
                    </Stack>
                    
                }
                <Stack sx={{ height: 100, width: 380, padding: 1, borderWidth: 1, borderColor: "green" }}>
                            {slot.length>=1 ?
                            <Stack   sx={{backgroundColor:"transparent",alignItems:'center'}}>
                            {slot.sort((a,b) => (a.starttime > b.endtime) ? 1 : ((b.endtime > a.starttime) ? -1 : 0)).map((x,index)=>{
                              return(
                                <Stack key={index} elevation={3} sx={{backgroundColor:"#6b625b",alignItems:'center', padding:1,marginTop:1,boxShadow:'initial',width:240,flexDirection:'row',justifyContent:'space-between'}}  >
                                  <Button variant='text' color='error' onClick={()=> filter_Time(x)}>x</Button>
                                  <Button variant="text"  sx={{color:"white"}}> من {x.starttime}:{x.startminuet}  الى {x.endtime}:{x.endminute} {statusMap[x.shift]}</Button>
                                  
                                </Stack>
                              )
                              })
                            }
                          </Stack>:
                          <Stack alignItems={'center'} padding={1} mt={8}>
                            <Typography variant='caption' color={'InfoText'} fontFamily={'Cairo'}> لاتوجد اوقات مدخله</Typography>
                         </Stack>
                          }
                </Stack>
            </Box>
            <Box  mt={4} height={"220px"} display={'flex'} bgcolor={colorsApp.ricePaper} flexDirection={'column'}>
                <Stack bgcolor={colorsApp.otrabrown2color} alignItems={'center'}>
                <Typography variant='h6' lineHeight={2.5} alignItems={'center'}>التاكيد النهائي</Typography>
                </Stack>
                <Stack mt={3} mb={3} padding={1} flexDirection={'column'} height={'200px'}  justifyContent={'space-between'}>
                    <Button disabled={enableTime} variant="contained" size='large' color="success"  onClick={()=>uploadSlot()}>
                        <Typography variant='body2' textAlign={'center'}>تاكيد اوقات الحجز</Typography>
                    </Button>
                    <Button disabled={enableTime} variant='outlined' size='large' color='warning' onClick={()=> window.location.reload()}>
                                <Typography>الغاء عملية اضافة اوقات الحجز</Typography>
                    </Button>
                </Stack>
            </Box>
                   
            


            </Grid>
            </Box>


       


    )
}

export default Bookingsystem;