import { formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import EllipsisVerticalIcon from '@heroicons/react/24/solid/EllipsisVerticalIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography
} from '@mui/material';
import { colorsApp } from '../../theme/colors';
import SendIcon from '@mui/icons-material/SendRounded';
export const OverviewLatestProducts = (props) => {
  const { products = [], sx } = props;

  return (
    <Card sx={sx}>
      <CardHeader title="طاولات  عطرة" />
      <List>
        {products.map((product, index) => {
          const hasDivider = index < products.length - 1;
         // const ago = formatDistanceToNow(product.updatedAt);

          return (
            <ListItem
              divider={hasDivider}
              key={product.id}
              sx={{ borderColor:"black", borderWidth:1,backgroundColor:colorsApp.ricePaper,marginLeft:2,marginTop:3}}
            >
              <ListItemAvatar>
                {
                  product.img
                    ? (
                      <Box
                        component="img"
                        src={product.img}
                        sx={{
                          borderRadius: 1,
                          height: 55,
                          width: 55,
                          margin:1
                        }}
                      />
                    )
                    : (
                      <Box
                        sx={{
                          borderRadius: 1,
                          backgroundColor: 'neutral.200',
                          height: 48,
                          width: 48
                        }}
                      />
                    )
                }
              </ListItemAvatar>
              <ListItemText
                primary={product.tabelname}
                primaryTypographyProps={{ variant: 'subtitle1' }}
                secondary={`tabel no ${product.tabelId} #`}
                secondaryTypographyProps={{ variant: 'body2' }}
              />
              <IconButton>
                <Button variant='contained' endIcon={<SendIcon/>} disableFocusRipple={true}  onClick={()=>props.handelItem(product.tabelId,product.tabelname)}>
                  <Typography variant='caption'>اختر للاكمال</Typography>
                </Button>
              </IconButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          )}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};

OverviewLatestProducts.propTypes = {
  products: PropTypes.array,
  sx: PropTypes.object
};
