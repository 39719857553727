import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { auth } from "../config/firbase";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Stack } from '@mui/material';
 export const AuthContext=React.createContext()


const ProtectedRoute = ({ children }) => {
  const { user } = useUserAuth();
  const[currentuser,setCurrentUser]=useState(null)
  const[pending,setPendinng]=useState(true)
  useEffect(()=>{
    auth.onAuthStateChanged((user)=>{
      setCurrentUser(user)
      setPendinng(false)
    })
  },[])

  if (pending){
    return<> <Box sx={{ display: 'flex' }}>
    <CircularProgress  size={100} color='primary' />
  </Box> </>
  }
  // console.log("Check user in Private: ", user.auth.currentUser);
  // console.log("Check user in Private: ", auth.onAuthStateChanged);
  // if (!user) {
  //   return <Navigate to="/login" />;
  // }
  return(
   <>
   {children}
   </>
    
  )
};

export default ProtectedRoute;