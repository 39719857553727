import { format } from 'date-fns';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';


import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Scrollbar } from '../../component/scrollbar';
import { SeverityPill } from '../../component/severity-pill';
import moment from 'moment/moment';

const statusMap = {
  pending: 'warning',
  delivered: 'success',
  refunded: 'error'
};

export const OverviewOrders = (props) => {
  const[reauest,setrequest]=useState([])
  const[loading,setloading]=useState(false)

  const location =useLocation()
  let navigate=useNavigate()

  return (
    <Card sx={props.sx} >
      <CardHeader title="Latest Orders" />
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>
                  Id#
                </TableCell>
                <TableCell>
                  Order
                </TableCell>
                <TableCell>
                  Customer
                </TableCell>
                <TableCell >
                  from
                </TableCell>
                <TableCell>
                  end to
                </TableCell>
                <TableCell sortDirection="asc">
                  booking time
                </TableCell>
                <TableCell>
                  gest no
                </TableCell>
                <TableCell>
                 price
                </TableCell>
                <TableCell>
                  total
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.request.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.bokingdate) - new Date(a.bokingdate);
      }).map((order) => {
                const createdAt = order.bokingdate? moment.utc(order.bokingdate).format('YYYY-MM-DD'):order.bokingdate;

                return (
                  <TableRow 
                    hover
                    key={order.id}
                  >
                    <TableCell>
                    {order.id}
                    </TableCell>
                    <TableCell>
                    {order.tabelname}
                    </TableCell>
                    <TableCell>
                      {order.phone}
                    </TableCell>
                    <TableCell >
                      {order.bokingstarttime}
                    </TableCell>
                    <TableCell>
                      {order.bokingendtime}
                    </TableCell>
                    <TableCell sortDirection="desc">
                      {createdAt}
                    </TableCell>
                    <TableCell>
                      {order.gest}
                    </TableCell>
                    <TableCell>
                      {order.price}
                    </TableCell>
                    <TableCell>
                      {order.total}
                    </TableCell>
                    <TableCell>
                      <SeverityPill color={statusMap[order.state]}>
                        {order.state}
                      </SeverityPill>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          )}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};

OverviewOrders.prototype = {
  orders: PropTypes.array,
  sx: PropTypes.object
};
