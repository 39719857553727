import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  Typography,
  Alert,
  IconButton,
  Stack,
  Container,
  SvgIcon,
  CardMedia,
} from "@mui/material";
import StarIcon from "@heroicons/react/24/solid/StarIcon";
import { IconContext } from "react-icons";
import { styled } from "@mui/material/styles";
import TabelService from "../../sevice/order.service";
import Paper from "@mui/material/Paper";
import Slider from "react-slick";
import SimpleSlider from "./slider";

import {
  Facebook,
  FavoriteBorder,
  Instagram,
  LinkedIn,
} from "@mui/icons-material";
import { colorsApp } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { worktime } from "./worktime";
import coffe from "../../theme/images/mainbk.png";

import WorktimePage from "../../component/worktimepage";
import MainTitle from "../../component/otratext";
import MainTitle2 from "../../component/otratext2";
import useWindowDimensions from "../../hooks/useWindowDimensions ";
import { useUserAuth } from "../../context/UserAuthContext";

const MainePage = (props) => {
  const { height, width } = useWindowDimensions();
  const { user } = useUserAuth();

  useEffect(() => {
    console.log("test user prrop", props);
  }, []);

  return (
    <Box
      component={"div"}
      className="bg"
      height={height}
      sx={{ alignItems: "center", flexDirection: "column" }}
    >
      <Stack
        alignItems={"center"}
        mt={10}
        sx={{
          display: "flex",
          width: "100%",
          backgroundColor: colorsApp.transparent,
        }}
      >
        <MainTitle  subtitle={"فرع بستان عطرة"} width={"350px"} />
        {/* <MainTitle2 text="لحظة ت راث وضيافة" width={"100%"} /> */}
      </Stack>

      <Stack
        flexWrap={"wrap"}
        sx={{
          width: "100%",
          justifyItems: "center",
          backgroundColor: colorsApp.transparent,
        }}
      >
        <SimpleSlider userauth={props.userStatues} />
      </Stack>

      <Stack   width={"100%"}>
        <WorktimePage />
      </Stack>
    </Box>
  );
};

export default MainePage;

// <Box
//       sx={{
//         display: 'flex',
//         flexWrap: 'wrap',
//         '& > :not(style)': {
//           m: 1,
//           width: 128,
//           height: 128,
//         },
//       }}
//     >
//       <Paper elevation={0} />
//       <Paper />
//       <Paper elevation={3} />
//     </Box>

// {tiers.map(tier => (
//   // Enterprise card is full width at sm breakpoint
//   // <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>

//   // </Grid>

//      <Card>
//       <CardHeader
//         title={tier.title}
//         subheader={tier.subheader}
//         titleTypographyProps={{ align: 'center' }}
//         subheaderTypographyProps={{ align: 'center' }}
//         action={tier.title === 'Pro' ? <StarIcon /> : null}
//         //className={classes.cardHeader}
//       />
//       <CardContent>
//         <div >
//           <Typography component="h2" variant="h3" color="textPrimary">
//             ${tier.price}
//           </Typography>
//           <Typography variant="h6" color="textSecondary">
//             /mo
//           </Typography>
//         </div>
//         {tier.description.map(line => (
//           <Typography variant="subtitle1" align="center" key={line}>
//             {line}
//           </Typography>
//         ))}
//       </CardContent>
//       <CardActions  >
//         <Button fullWidth variant={tier.buttonVariant} color="primary">
//           {tier.buttonText}
//         </Button>
//       </CardActions>
//     </Card>

// ))}
