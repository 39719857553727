import PropTypes from 'prop-types';
import BellIcon from '@heroicons/react/24/solid/BellIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha } from '@mui/material/styles';
import { AccountPopover } from './account-popover';
import { usePopover } from '../hooks/use-popover';
import logo from '../../src/theme/images/Etruh 4.svg'
import { FiBookOpen, FiCalendar, FiTable } from 'react-icons/fi';
import { colorsApp } from '../theme/colors';
const SIDE_NAV_WIDTH = 0;
const TOP_NAV_HEIGHT = 64;


export const TopNav = (props) => {
  const { onNavOpen ,handelScreen} = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover();


  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: colorsApp.otrabrown1colorlight,
          position:'static',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width:`calc(100% - ${SIDE_NAV_WIDTH}px)`,
          zIndex: 1
        }}
      >
        <Stack
          alignItems='stretch'
          direction="row"
          display={'flex'}
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
            backgroundColor:colorsApp.transparent
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={4}
          >
          
            {!lgUp ?(
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
               
            ):(
              <Stack sx={{flexDirection:'row',alignItems:'stretch'}}>
                <img src={logo} width={55} height={55} alt='logo'/>
                <Typography variant="h4" color={colorsApp.otraColotText}>
                عطرة
              </Typography>
              </Stack>
             
            )}
            
          </Stack>
          {/* header for item */}
          <Stack
            alignItems="center"
            direction="row"
            spacing={6}
          >
            <Tooltip onClick={()=>handelScreen("panel")}>
              <Typography variant='caption' color={colorsApp.otraColotText} lineHeight={'1.4'}  ml={2} >
                انشاء طاوله
              </Typography>
              <IconButton>
                <FiTable size={'33'} color={colorsApp.otraGreenColor} onClick={()=>handelScreen("panel")}/>
              </IconButton>
            </Tooltip>
            <Tooltip  onClick={()=>handelScreen("bookingsystem")}>
              <Typography variant='caption' color={colorsApp.otraColotText} lineHeight={'1.4'} ml={2} >
                كروت  موواعيد الطاولات الخاصة  
              </Typography>
              <IconButton>
                <FiCalendar size={'33'} color={colorsApp.otraGreenColor} onClick={()=>handelScreen("bookingsystem")}/>
              </IconButton>
            </Tooltip>
            <Tooltip onClick={()=>handelScreen("otrapecnek")}>
              <Typography variant='caption' color={colorsApp.otraColotText} lineHeight={'1.4'} ml={2}>  
              كروت مواعيد جلسة عطرة
              </Typography>
              <IconButton>
                <FiBookOpen size={'33'} color={colorsApp.otraGreenColor} onClick={()=>handelScreen("orderpanel")}/>
              </IconButton>
            </Tooltip>
            <Tooltip onClick={()=>handelScreen("orderpanel")}>
              <Typography variant='caption' color={colorsApp.otraColotText} lineHeight={'1.4'} ml={2}  > 
                استعراض الحجوزات
              </Typography>
              <IconButton>
                <FiBookOpen size={'33'} color={colorsApp.otraColotText} onClick={()=>handelScreen("orderpanel")}/>
              </IconButton>
            </Tooltip>
            

           
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: 'pointer',
                height: 40,
                width: 40
              }}
              src={logo}
            />
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func
};
