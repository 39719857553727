import React,{useEffect,useState} from "react"
import PropTypes from 'prop-types';
import BellIcon from '@heroicons/react/24/solid/BellIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';

import useMediaQuery from '@mui/material/useMediaQuery';
import { AccountPopover } from './account-popover';
import { usePopover } from '../hooks/use-popover';
import { colorsApp } from '../theme/colors';
import { MdOutlineShoppingCart } from "react-icons/md";
import Otra from '../../src/theme/images/otratranspertLogo.png'
import { useUserAuth } from '../context/UserAuthContext';
import { TbHomeUp } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import calenderLogo from '../theme/images/calendar.jpg'
import { HomeMini } from "@mui/icons-material";
import useWindowDimensions from "../hooks/useWindowDimensions ";
const SIDE_NAV_WIDTH = 0;
const TOP_NAV_HEIGHT = 30;

let USERDATA
let userinfo="0554343"

export const TopNavMaine = (props) => {
  const { onNavOpen } = props;
  const {width,height} =useWindowDimensions()
  const [anchorEl, setAnchorEl] =useState(null);
  const open = Boolean(anchorEl);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const accountPopover = usePopover();
  const {user}=useUserAuth()
   let navigate =useNavigate()

   const { logOut} = useUserAuth();
  useEffect(()=>{
       
    if(user){
      USERDATA={ 
    email:user.email===null?"":user.email,
    phone:user.phoneNumber===null?"":user.phoneNumber,
    userid:user.uid,
    accsesstoken:user.accessToken
  }
  if(USERDATA.email===""){
    userinfo=USERDATA.phone
  }else{
    userinfo=USERDATA.userid
  }
  //console.log("test user from Nav",userinfo)

 
   
    }
    
},[user])

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};


  

const handleSignOut = async () => {
  try {
    handleClose?.();
    await logOut();
    navigate("/login");
  } catch (error) {
    //console.log(error.message);
  }
};
 
  return (
    <>
      <Box
      
        
        sx={{
         // backdropFilter: 'blur(6px)',
          backgroundColor:colorsApp.otraColotText,
          position:'static',
          borderBottomRightRadius:33,
          borderBottomLeftRadius:33,
           
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          height:height-"400px",
          top: 0,
          width:width,
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Stack alignItems="center">
            <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              color='primary'
              ref={accountPopover.anchorRef}
              size='medium'
              sx={{ ml:1,zIndex:1}}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
            <Avatar  sx={{ width: 44, height: 44,backgroundColor:colorsApp.otrabrown1color ,color:colorsApp.otraGreenColor}}>Utra</Avatar>
          </IconButton>
        </Tooltip>

        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 33,
              height: 33,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              backgroundClip:colorsApp.otrabrown1color,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar />
          <Typography color={colorsApp.otraColotText} fontSize={"18px"}>
              Welcome-{userinfo}
          </Typography>
        </MenuItem>
        <MenuItem onClick={()=>navigate("/overviewLatestOrders",{
              state:{
                phone:userinfo
              }
            })}>
          <Avatar alt="Remy Sharp"
            src={calenderLogo}
            sx={{ width: 56, height: 56 }}
            /> <Typography color={colorsApp.otraColotText} fontSize={"18px"}>
                حجوزاتي
              </Typography>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>
          <ListItem>
            <PersonAdd fontSize="small" />
          </ListItem>
          Add another account
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography color={colorsApp.otraColotText} fontSize={"18px"}>
            تسجيل خروج
            </Typography>
        </MenuItem>
      </Menu>
          </Stack>
          <Stack alignItems={'center'} justifyContent={"center"}>
          <Avatar sx={{
               cursor:'crosshair',
              //  position:'relative',
              //  top:"22px",
                mt:1,
                height: 33,
                width: 33,
                
                backgroundColor:colorsApp.otraGreenColor,
                display:'inline-block',
                // borderBottomLeftRadius:18,
                // borderBottomRightRadius:18,
                overflow:"hidden",
                objectFit:'contain'
              }}
              src={`${Otra}`}/>
          
          </Stack>
          <Stack
           
            
            sx={{backgroundColor:"transparent" }}
           // spacing={2}
          >
            
              <IconButton onClick={()=>navigate("/")} sx={{mr:1}}>
                <SvgIcon fontSize="large"  >
                  <TbHomeUp color={colorsApp.otrabrown1color} size={"22px"}/>
                </SvgIcon>
              </IconButton>
           
            
             
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
        user={userinfo}
      />
    </>
  );
};

TopNavMaine.propTypes = {
  onNavOpen: PropTypes.func
};
