import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
//import Stack from '@mui/material/Stack';
// import NextLink from 'next/link';
// import { useRouter } from 'next/navigation';
// import { useFormik } from 'formik';
 import{doc,setDoc} from 'firebase/firestore'
 
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { MuiTelInput } from 'mui-tel-input'
import { useUserAuth } from '../context/UserAuthContext';
import { auth, db } from '../config/firbase';
import {FiCommand} from 'react-icons/fi'
import OTPInput from 'react-otp-input';
import { CgSearchLoading } from 'react-icons/cg';
 import {colorsApp} from '../../src/theme/colors'
 import MainTitle from '../../src/component/otratext'
import { getAuth, RecaptchaVerifier,signInWithPhoneNumber  } from "firebase/auth";
import Logo from '../../src/theme/images/otraico3.png'
import MainTitle2 from '../component/otratext2';

// import { useAuth } from 'src/hooks/use-auth';
// import { Layout as AuthLayout } from 'src/layouts/auth/layout';

const SignUpPage = () => {
//   const router = useRouter();
//   const auth = useAuth();
  const [method, setMethod] = useState('phoneNumber');
  const [showErorr, setshowErorr] = useState(false);
  const[helperText,sethelperText]=useState("thanks use text")
  const [value, setValue] = useState("")
  const[email,setemaile]=useState("")
  const[password,setpassworde]=useState("")
  const[Erorr,setError]=useState("")
  const[phase,setphase]=useState('regester')
  const[phone,setphone]=useState("")
  const[otp,setotp]=useState('')
  const[loading,setloding]=useState(false)
  const[msg,setmsg]=useState('')
  const[showMsg,setshhowMsg]=useState(false)
   
  const  {signUp,setUser}  = useUserAuth();

  let navigate =useNavigate()

   
useEffect(()=>{
  if(showMsg){
    setTimeout(() => {
      setshhowMsg(false)
    }, 3000);
  }
},[showMsg])
 
 

const handleChange = (newPhone) => {

  //console.log(newPhone)
  setphone(newPhone)
}
  const handleMethodChange = useCallback(
    (event, value) => {
      setMethod(value);
    },
    []
  );
  const handelPhaseChange=(val)=>{
    setphase(val)
    if(val==="complete"){
      setloding(true)
    }
  }
  function onCapchVerview(){
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSingupInSubmit();
        }
      });
  }

  }
  
const onSingupInSubmit =()=>{
  setloding(true)
  onCapchVerview()
  const phoneNumber = phone;
  const appVerifier = window.recaptchaVerifier;
  //console.log("test phone from singup",phone)
  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      setloding(false)
      setmsg(`تم ارسال كود التحقق على ${phone}`)
      setshhowMsg(true)
      handelPhaseChange("authcode")
      // ...
    }).catch((error) => {
      //console.log("Erorr from singup" ,error)
      setloding(false)
      setshhowMsg(false)
    });
}

function getauthbyOtp(){
  const code = otp;
  //console.log("user OOtb ",code)
  
  window.confirmationResult.confirm(code).then((result) => {
  // User signed in successfully.
  const user = result.user;
  //console.log("user sign by phone successfully ",user)
  setUser(user)
  handelPhaseChange("complete")
  // ...
}).catch((error) => {
  //console.log("user sign by  code phone Errr=> ",error)
  handelPhaseChange("complete")
  // User couldn't sign in (bad verification code?)
  // ...
});

}

  const handleChangeSubmit = async(e) => {
    e.preventDefault();
    setError("");
    try {
      await signUp(email, password).then(async(res)=>{
        //console.log("test singup Function ",res)
        const userid = res.user.uid;
        setTimeout(() => {
          navigate("/");
      }, 3000);

        try {
          await setDoc(doc(db, "users", userid), {
              fullName: "user  otra",
              username: "otrauser",
              email: email,
              dateOfBirth: new Date(),
          });
      } catch (e) {
          console.error("Error adding document: ", e);
      }

      });
     
     
     
    } catch (err) {
      setError(err.message);
    }

  }
   
  const getOpt=(code)=>{
    //console.log("")
  }


//   const handleSkip = useCallback(
//     () => {
//       auth.skip();
//       router.push('/');
//     },
//     [auth, router]
//   );

  return (
    <>
      {showMsg&&<Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          {msg}
      </Alert>}
      <Stack >
        <title>
          تسجيل الدخول|  عـــطـــرة
        </title>
      </Stack>
      <Box
        sx={{
          flexDirection:'column',
          backgroundColor: colorsApp.backgroundPage,
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
         <MainTitle width={"100%"} margin={4}/>
         <MainTitle2 text={"مركاز عطرة"} />
         <Stack>
          <img src={Logo} width={55} height={55} alt='logo'/>
         </Stack>
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%'
          }}
        >
          <div>
            <Stack
              spacing={1}
              sx={{ mb: 3 }}
            >
              <Typography variant="h4" textAlign={'center'} fontFamily={"Cairo"}  color="text.secondary">
                تسجيل الدخول
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                
              >
                Already have an account?
                &nbsp;
                <Link  component={'button'} onClick={()=>navigate("/login")}>Sign in</Link>
            
              </Typography>
              
            </Stack>
            <Tabs
              onChange={handleMethodChange}
              sx={{ mb: 3 }}
              value={method}
            >
              <Tab
                label="Email"
                value="email"
              />
              <Tab
                label="Phone Number"
                value="phoneNumber"
              />
            </Tabs>
            {method === 'email' && (
              <form
                noValidate
                onSubmit={handleChangeSubmit}
              >
                <Stack spacing={3}>
                  <TextField
                    error={!!showErorr}
                    fullWidth
                    //helperText={formik.touched.email && formik.errors.email}
                    label="Email Address"
                    name="email"
                    // onBlur={formik.handleBlur}
                     onChange={(e)=>setemaile(e.target.value)}
                    type="email"
                    // value={formik.values.email}
                  />
                  <TextField
                    error={!!(showErorr)}
                    fullWidth
                    helperText={showErorr}
                    label="Password"
                    name="password"
                    // onBlur={formik.handleBlur}
                    onChange={(e)=>setpassworde(e.target.value)}
                    type="password"
                    //value={formik.values.password}
                  />
                </Stack>
               
                {Erorr && (
                  <Typography
                    color="error"
                    sx={{ mt: 3 }}
                    variant="body2"
                  >
                    {Erorr}
                  </Typography>
                )}
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                >
                  Continue..
                </Button>
                
                 
              </form>
            )}
          {method === 'phoneNumber' && (
            <div>
              <Stack  width={"100%"} alignItems={'center'}>
                {phase==="regester"&&
                    <Stack width={"100%"} alignItems={'center'}>
                    < FormControl>
                        <FormLabel>رقم الجوال</FormLabel>
                        <MuiTelInput  defaultCountry='SA' value={phone} onChange={handleChange} />
                        <Button
                          //id='sign-in-button'
                          fullWidth
                          size="large"
                          sx={{ mt: 3 }}
                          type="submit"
                          color="info"
                          variant="contained"
                          onClick={()=>onSingupInSubmit()}
                        >
                          ارسال رمز التحقق
                        </Button>
                    </FormControl>
                    </Stack>
                  }

                  
                  {phase==="authcode"&&
                    <Stack width={"100%"} alignItems={'center'}>
                      <FormLabel sx={{mt:2,mb:2,alignContent:'center',textAlign:'center',fontSize:22}}>كود التحقق</FormLabel>
                      <OTPInput
                        containerStyle={{backgroundColor:"transparent",justifyContent:'space-around',display:'block'}}
                        inputStyle={{marginLeft:1,fontSize:22,marginRight:1,marginBottom:1}}
                        value={otp}
                        onChange={setotp}
                        numInputs={6}
                        renderSeparator={<span> - </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                      <Button
                        fullWidth
                        size="large"
                        sx={{ mt: 3 }}
                        type="submit"
                        variant="contained"
                        onClick={()=>getauthbyOtp()}
                      >
                        تحقق من الرمز 
                      </Button>
                      </Stack>
                  }
                      
                   
                    


                </Stack>
                 {!loading&&<FiCommand size={22} className="loading-icon" />}
              <div>
                <button id='sign-in-button' hidden={true} >rech</button>
              </div>
            </div>
               
          )}
          </div>
        </Box>
        
      </Box>
    </>
  );
};
export default SignUpPage;
// Page.getLayout = (page) => (
//   <AuthLayout>
//     {page}
//   </AuthLayout>
// );

// export default Page;
