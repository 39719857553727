import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,Select, 
  Unstable_Grid2 as Grid,MenuItem, 
  Typography,
  Alert,IconButton,
  Stack,Container, SvgIcon,indc, ListItem, ListItemButton, ListItemText, InputLabel, RadioGroup, FormControl, FormControlLabel, Radio, FormLabel, List, ListItemIcon, Autocomplete, NativeSelect,
  Input
} from '@mui/material';
import { styled } from '@mui/material/styles';
import OrderService from "../../sevice/request.service"
  
import { colorsApp } from '../../theme/colors';
import { TopNavMaine } from '../../layouts/top-nav-main';
import MainTitle from '../../component/otratext';
import MainTitle2 from '../../component/otratext2';
import FoterPage from '../../layouts/footerPahe'; 
import { useLocation, useNavigate} from 'react-router-dom'
import WorktimePage from '../../component/worktimepage';
import { useUserAuth } from '../../context/UserAuthContext';
import { FiDelete, FiLoader, FiPlus } from 'react-icons/fi';
import axios from 'axios';
import moment from 'moment';
import useWindowDimensions from '../../hooks/useWindowDimensions ';
import { LayoutMainpage } from '../../layouts/layoutmainpage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../config/firbase';
import { AccessAlarmOutlined, PlaceOutlined, CalendarMonthRounded, LocationDisabledRounded, SearchRounded, SearchOff, AddAlarmSharp, TimeToLeave, AddAlert, PersonAddAlt1Sharp, MarkChatRead, DeleteForeverOutlined, Clear } from '@mui/icons-material';
import { CiLocationOn } from "react-icons/ci";
import { FaRegCalendarAlt } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import { redirect } from "react-router-dom";
import { Sheet } from 'react-modal-sheet';
var qs = require('qs');


const OrderCollectionRef=collection(db,"slots2")
let USERDATA
let userinfo="0554343"
let  intervalId 


const Mainpicknek=(props)=>{
const[loading,setloding]=useState(false)
const[pikOrder,setPikOrder]=useState(false)
const[gest,setgest]=useState(1)
const[userphone,setuserphone]=useState('')
const[price,setprice]=useState(40)
const[disable,setdisable]=useState(true)
const [value, setValue] =  useState(dayjs(new Date()))
let navigate=useNavigate()
 const location =useLocation()
 const {user}=useUserAuth()
 const {width,height} =useWindowDimensions()
const[slots,setslots]=useState([])
const[showslot,setshowslot]=useState(false)
const[timeHolder,setTimeHolder]=useState([])
const[showmsg,setMsg]=useState(false)
const[showBox,setShowBox]=useState(false)
const[name,setname]=useState("")
const[name1,setname2]=useState("")
const[gestEmaile,setgestEmaile]=useState("")
const[paymentUrl,setpaymentUrl]=useState('')
const[paymentref,setpaymentref]=useState('')
const [showfrme,setshowfrme]=useState(false)
const[showCalender,setshowCalender]=useState(true)
const[showButtonAccsepte,setshowButtonAccsepte]=useState(false)
const[finallyMsg,setfinallyMsg]=useState(false)
 const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
 
const [isOpen, setOpen] = useState(false);
 
  
const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    maxWidth: width,
    maxHeight:height,
    flex: '1 1 auto',
    backgroundColor:colorsApp.backgroundimage,
  //   [theme.breakpoints.up('lg')]: {
  //     paddingLeft: SIDE_NAV_WIDTH
  //   }
  }));
  
  const LayoutContainer = styled('div')({
    width:width,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    backgroundColor:colorsApp.backgroundimage,
     
  });

  useEffect(()=>{
   
     if(pikOrder){
      setTimeout(()=>{
        setloding(false)
       // navigate("/landpage")
      },4000)
     }
  },[pikOrder])


  useEffect(()=>{
   
    if(slots.length>1){
     console.log("hayy",slots.length)
    }
      
 },[slots])


  useEffect(()=>{
   
    if(showmsg){
     setTimeout(()=>{
       setMsg(false)
      
     },4000)
    }
 },[showmsg])



useEffect(()=>{
       
    if(user){
      USERDATA={ 
      email:user.email===null?"":user.email,
      phone:user.phoneNumber===null?"":user.phoneNumber,
      userid:user.uid,
      accsesstoken:user.accessToken
  }
  if(USERDATA.email===""){
    userinfo=USERDATA.phone
    setuserphone(USERDATA.phone)
    console.log("tes nomber",userinfo)
  }else{
    userinfo=USERDATA.email
    console.log("tes nomber",userinfo)
  }
  
  //console.log("test user from Nav",userinfo)

 
   
    }
    console.log("startvalue ,==",disable)
    ///calclute total booleng 
    getSlotByval2()
},[])

useEffect(() => {
  window.addEventListener('change', (event)=>{
    console.log("test listen",event)
  });

  // cleanup this component
    // return () => {
    //   window.removeEventListener('change',event);
    // };
}, []);


 const makeNewOrder=(val)=>{
    //console.log(val)
    setgest(val)
    setprice(val*price)
 }


  const submitOrder=async()=>{
    setPikOrder(true)
    setshowslot(false)
    setShowBox(false)
    setshowButtonAccsepte(false)
      
  } 

  const canselitOrder=async()=>{
    setPikOrder(false)
    setshowslot(true)
    setShowBox(true)
    setshowButtonAccsepte(false)
    
    
  } 

  const orderDone=async()=>{
    setPikOrder(false)
    setshowslot(false)
    setShowBox(false)
    setshowCalender(false)
    setshowButtonAccsepte(false)
    setfinallyMsg(true)
  } 


  const pikorderToBooking=async()=>{
    setloding(true)
    const calclutBooking=price*timeHolder.length
    const total= ((calclutBooking*0.15+Number(calclutBooking)))
    const orderdata={
        bokingdate:dayjs(value).format("YYYY-MM-DD"),
        bokingendtime:"09",
        bokingstarttime:"06",
        condition:"main pickinc for  otra",
        discount:false,
        discountValue:0,
        duration:"720",
        gest:10,
        img:'',
        phone:Number(userinfo),
        state:"group",
        tabelId:'999',
        tabelavilble:true,
        tabeldetails:"مجلس عطره العام",
        tabelname:"مجلس عطرة العام",
        total:total,
        slots:timeHolder
    }
   
    
    if(orderdata){
      
      await OrderService.addorder(orderdata).then((res)=>{
        console.log("order puk up",res)
        setPikOrder(true)
         sendMSG(orderdata)
         })
    }
   
  }
  const sendMSG=(orderdata)=>{
    const{phone,tabelname,tabelId,gest,bokingdate,bokingstarttime}=orderdata
    var data = qs.stringify({
      "token": "q7tjlgnzrz4cmtsb",
      "to":phone,
      // "body":`طاوله ${tabelname} رقم ${tabelId} وعدد الاشخاص ${gest} سوف يتم مراجعة الطلب والرد بتاكيد الحجزمع تحيات بستان عطرة`
      "body":` تشكر لكم حجزكم في بستان عطرة  ${moment(bokingdate).format("DD-MM-YY")} الساعة ${bokingstarttime} مساء  
      طاولة  ${tabelname}   رقم# ${tabelId} وعدد الاشخاص ${gest} 
      لتاكيد الحجز الرجاء التحويل الى حساب رقم SA4180000419608016079084
      باسم مقهى عطرة الجادة 
      وارسال ايصال التحويل`  
  });
  
  var config = {
      method: 'post',
      url: 'https://api.ultramsg.com/instance80963/messages/chat',
      headers: {  
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : data
    };
  const response1= axios(config)
  .then(function (response) {
    //console.log("Test api xxxx",JSON.stringify(response.data));
    //res.status(201).send(response.data)
  })
  .catch(function (error) {
    //console.log(error);
  });

  return response1

  }

  

const getSlotByval2=async( )=>{
  const timeNow = dayjs(new Date(value)).format('YYYY-MM-DD');
   const q = query(
    OrderCollectionRef,
     //  where('owner', '==', currentUserId),
     where('slotdate', '==',timeNow ), // does not need index
     //where("phone" ,"==",Number(userinfo)) // needs index  https://firebase.google.com/docs/firestore/query-data/indexing?authuser=1&hl=en
     // orderBy('score', 'asc'), // be aware of limitations: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
     // limit(1)
   );
 
     
   const unsub =  onSnapshot(q, (querySnapshot) => {
     const items = [];
     querySnapshot.forEach((doc) => {
       items.push(doc.data());
        //console.log("start get slot from backend,==",items)
     }); 
      const result= items.filter((xitem)=>{
      const startTime =dayjs.unix(xitem.slottime).$H ;
      const timeNow = dayjs(new Date()).$H;
      // console.log("from arra2y", startTime ," /// ",timeNow  )
        return    startTime >= (timeNow)
      })
      console.log("start get slot from FELTER ,=xxx= 22",result)
     
     // setIntialCardInfo(result)
      setslots(result)
      setshowslot(true)
    });
   return () => {
     unsub();
   };
  
 }


 useEffect(()=>{
  console.log("test item slot",timeHolder)
 },[timeHolder])


const bookingTime=(items)=>{
  const singl_slot=  timeHolder.includes(items)
  console.log("slot....",items)
    if(singl_slot){
       setMsg(true)
    }else{
      setTimeHolder([...timeHolder,items])
      setdisable(false)
      setShowBox(true)
      setshowButtonAccsepte(true)
    }
   
}

const filter_selec_slot=(item)=>{
  const singl_slot=timeHolder.filter((slot)=>{
    return slot!=item
  })
  setTimeHolder(singl_slot)
  //singl_slot.filter(item)
  //setTimeHolder(singl_slot)

}

const makePaymment=async()=>{
  const api = axios.create({
    baseURL: 'http://localhost:3000',
    // headers: {
    //   post: {
    //     "Access-Control-Allow-Origin": true
    //   }
    // }
  })

  const motherId = "128388zxxz"
  const options={
      "method": "create",
      "store": "CAFE ATRA ALJADE",
      "authkey": "GrK6^7gsFH#8pp4z",
      "framed": 0,
      "language":"AR",
      "ivp_applepay": "1",
      "order": {
        "cartid":"1234",
        "test": "1",
        "amount": 1,
        "currency": "SAR",
        "description": "Test Transaction from me",
        "trantype": "sale" 
      },
      "customer": {
        "ref": USERDATA.userid,
        "email": "ziayd2009@gmail.com",
        "name": {
          "forenames":"ziayd",
          "surname": "aljohani"
        },
        "address": {
          "line1": "Al-maddinah mnwarrah aminah",
          "city": "Maddinah",
          "country": "SA"
        },
        "phone":"966543437660"
      },
      "return": {
        "authorised": "https://www.merchantdomain.com/successful/?Txnorder=TESTing_153677468",
        "declined": "https://www.merchantdomain.com/declined/?Txnorder=TESTing_153677468",
        "cancelled": "https://www.merchantdomain.com/cancelled/?Txnorder=TESTing_153677468"
      }
    
  
    
  }

  const headers = { 
    'Authorization': 'Bearer my-token',
    'My-Custom-Header': 'foobar'
};
const instance = axios.create({
  baseURL: "http://localhost:3000/",
  timeout: 1000,
  headers: {'X-Custom-Header': 'foobar'}
});
   
  instance.post('https://secure.telr.com/gateway/order.json'
  ).then((res)=>{
    console.log("test fro GETWAY ===",res.data)
    return res.data
  }).catch((err)=>console.log("Erorr==",err))
  
   
    
}

const makePaymment1=()=>{
  const motherId = "128388zxxz"
  const options={
    "method": "create",
    "store": "CAFE ATRA ALJADE",
    "authkey": "GrK6^7gsFH#8pp4z",
    "framed": 1,
    "language":"AR",
    "ivp_applepay": "1",
    "order": {
      "cartid":"1234",
      "test": "1",
      "amount": 1,
      "currency": "SAR",
      "description": "Test Transaction from me",
      "trantype": "sale" 
    },
    "customer": {
      "ref": motherId,
      "email": "ziayd2009@gmail.com",
      "name": {
        "forenames":"ziayd",
        "surname": "aljohani"
      },
      "address": {
        "line1": "Al-maddinah mnwarrah aminah",
        "city": "Maddinah",
        "country": "SA"
      },
      "phone":"966543437660"
    },
    "return": {
      "authorised": "https://www.merchantdomain.com/successful/?Txnorder=TESTing_153677468",
      "declined": "https://www.merchantdomain.com/declined/?Txnorder=TESTing_153677468",
      "cancelled": "https://www.merchantdomain.com/cancelled/?Txnorder=TESTing_153677468"
    }
  }

  fetch('https://secure.telr.com/gateway/order.json',{
    mode: 'no-cors',
    method: "post",
    headers: {
         "Content-Type": "application/json"}
  }, options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));
}

const mKpaymnet= async()=>{
  console.log("start")
  const calclutBooking=price*timeHolder.length
  if(name==="" || name1==="" || gestEmaile===""){
    return alert("الرجاد التاكد من بيانات الضيف")
  }
  const options={
    "method": "create",
    "store": "CAFE ATRA ALJADE",
    "authkey": "GrK6^7gsFH#8pp4z",
    "framed": 0,
    "language":"AR",
    "ivp_applepay": "1",
    "order": {
      "cartid":uuidv4(),
      "test": "1",
      "amount":  (calclutBooking*0.15+Number(calclutBooking)),
      "currency": "SAR",
      "description": "booking in  main piknick",
      "trantype": "sale" 
    },
    "customer": {
      "ref": "211xx11",
      "email": gestEmaile,
      "name": {
        "forenames":name,
        "surname": name1
      },
      "address": {
        "line1": "Al-maddinah mnwarrah aminah",
        "city": "Maddinah",
        "country": "SA"
      },
      "phone":userphone
    },
    "return": {
      "authorised": "https://www.merchantdomain.com/successful/?Txnorder=TESTing_153677468",
      "declined": "https://www.merchantdomain.com/declined/?Txnorder=TESTing_153677468",
      "cancelled": "https://www.merchantdomain.com/cancelled/?Txnorder=TESTing_153677468"
    }
  

  
}

await axios.post("http://localhost:4000/makePaymment",options).then((responded)=>{
  console.log("r ====",responded)
  setpaymentUrl(responded.data.order.url)
  setpaymentref(responded.data.order.ref)
  paymenttStatuse(responded.data.order.ref)
}).finally((res)=>{
  setOpen(true)
  setshowfrme(true)

})
 

}

const chikePayment=async(val)=>{
  console.log("start chike with ref ====",val)
  await axios.post("http://localhost:4000/chikpayment",{ref:val}).then((responded)=>{
    console.log("r ====",responded)
    if (responded.data.order.status.code==3){
      deletTimeslots()
      clearInterval(intervalId)
      setOpen(false)
      orderDone()
      setTimeHolder([])
      console.log("ok ok ok",responded.data)
    }
    
  }).finally(()=>{
    console.log("rfrence  ====",paymentref)
  })
}

const paymenttStatuse=(val)=>{
   
    intervalId=setInterval(() => {
    console.log("start chike payment ")
    chikePayment(val)
  }, 3000);
  
}
 

const deletTimeslots=async()=>{
   console.log("cards",timeHolder)
  //await axios.post("http://localhost:4000/firbasequery1",{data:{date:"2024-08-13",starttime:1}}).then((responded)=>{
    await axios.post("http://localhost:4000/firbasequery1",{data:timeHolder}).then((responded)=>{
    console.log("r ====",responded  )
})

}

const paymentDone=()=>{
  setshowCalender(false)
  setshowButtonAccsepte(false)
}

    return (
      <>
        <LayoutMainpage>
          <TopNavMaine />
          <Box sx={{ alignItems: "center" }}>
            <Stack alignItems={"center"}>
              <MainTitle width={"336px"} margin={5} />
              <MainTitle2 width={"100%"} text={" عطرة براند محلي يجسد. هوية المدينة المنورة وضيافتها "} />
            </Stack>
            {showmsg && (
              <Stack alignItems="center" mt={1} mb={2} width={width}>
                <Alert variant="filled" severity="error">
                  عفوا لقد تم اختيار الوقت مسبقا
                </Alert>
              </Stack>
            )}
            
            <Box
              flexDirection={"row"}
              bgcolor={colorsApp.otraMaintext}
              border={0.8}
              borderColor={colorsApp.black}
              borderRadius={2}
              mb={1}
               
            >
              {showCalender&&
               <Stack flexDirection={"row"} padding={0.4} alignItems={"stretch"}>
               <Stack
                 flexDirection={"row"}
                 justifyContent={"space-around"}
                 spacing={2}
                 width={"120px"}
               >
                 <Stack alignItems={"center"} justifyItems={"center"}>
                   <CiLocationOn
                     fontSize={33}
                     color={colorsApp.otraGreenColor}
                     style={{ marginTop: "12px" }}
                   />
                 </Stack>
                 <Stack alignItems={"center"} justifyItems={"center"}>
                   <Typography
                     color={colorsApp.otraGreenColor}
                     sx={{ fontSize: 15, fontWeight: "bold" }}
                   >
                     مجلس عطرة
                   </Typography>
                 </Stack>
               </Stack>

               {/* Calendar view */}
               <Stack flexDirection={"row"} width={"140px"} ml={4}>
                 <Stack alignItems={"center"} justifyItems={"center"} mr={1}>
                   <FaRegCalendarAlt
                     fontSize={33}
                     color={colorsApp.otraGreenColor}
                     style={{ marginTop: "12px" }}
                   />
                 </Stack>
                 <Stack
                   alignItems={"center"}
                   justifyItems={"center"}
                   width={"120px"}
                   mt={2}
                 >
                   <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DatePicker
                       slotProps={{
                         textField: { variant: "standard", color: "error" },
                       }}
                       sx={{ alignItems: "center" }}
                       defaultValue={dayjs(new Date())}
                       onChange={(newValue) => setValue(newValue)}
                       disablePast
                       format="YYYY/MM/DD"
                       views={["year", "month", "day"]}
                     />
                   </LocalizationProvider>
                 </Stack>
               </Stack>
               {/* // end clender// */}
               <Stack flexDirection={"row"} alignItems={"baseline"} ml={8}>
                 <IconButton
                   aria-label="serach"
                   size="large"
                   onClick={() => getSlotByval2()}
                   edge="start"
                 >
                   <SearchRounded
                   
                     color={colorsApp.otraGreenColor}
                     fontSize="48"
                   />
                 </IconButton>
               </Stack>
             </Stack>
              }
             
            </Box>
            <Stack
              flexDirection={"column"}
              alignItems={"center"}
              display={"block"}
             
            >
              {showslot && (
                <Stack
                  sx={{ backgroundColor: "transparent", alignItems: "center" }}
                >
                  {slots
                    .sort((a, b) =>
                      a.slottime > b.slottime
                        ? 1
                        : b.slottime > a.slottime
                        ? -1
                        : 0
                    )
                    .map((x, index) => {
                      return (
                        <Stack
                          key={index}
                          
                          sx={{
                            backgroundColor: "#6b625b",
                            justifyContent:'space-around',
                            flexDirection: "row",
                            padding: 1,
                            marginTop: 1,
                            boxShadow: "initial",
                            width: "280px",
                            borderRadius:22
                          }}
                        >
                          <IconButton
                            aria-label="serach"
                            size="large"
                            onClick={() => bookingTime(x, index)}
                            sx={{ justifyContent: "space-around" }}
                          >
                            <FiPlus
                              color={colorsApp.whites}
                              size={30}
                              style={{ marginRight: 3 }}
                            />
                            <Typography color={"white"} variant="button">
                              من {x.starttime}:{x.startminuet} الى {x.endtime}:
                              {x.endminute}{" "}
                              {x.starttime > 12 ? "(مساء)" : "(ليلا)"}
                            </Typography>
                          </IconButton>
                        </Stack>
                      );
                    })}
                </Stack>
              
              )}
             
            </Stack>
            
            <Stack alignItems={"center"}>
              {showBox && (
                <Stack
                  border={0.5}
                  flexDirection={"column"}
                  bgcolor={colorsApp.ricePaper}
                  width={"320px"}
                  mt={2}
                  mb={1}
                  borderColor={"ActiveBorder"}
                  justifyContent={"space-around"}
                >
                  <Stack
                    justifyContent={"space-between"}
                    borderBottom={1}
                    padding={1}
                    borderColor={"black"}
                    bgcolor={colorsApp.otrabrown2color}
                    flexDirection={"row"}
                  >
                    <Stack alignContent={"center"} alignItems={"center"}>
                      <Typography
                        variant="caption"
                        textAlign={"center"}
                        color={colorsApp.newTextClr}
                        fontWeight={'bold'}
                      >
                        سعر الساعة
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        variant="caption"
                        textAlign={"right"}
                        color={colorsApp.otrasubBannerText}
                      >
                        {price} SR
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack flexDirection={"row"} spacing={1}>
                    <Stack
                      alignContent={"center"}
                      alignItems={"center"}
                      flexDirection={"row"}
                    >
                      <Typography
                        variant="caption"
                        textAlign={"center"}
                        color={colorsApp.otrabrownhevy}
                      >
                        الساعات المضافه{" "}
                      </Typography>
                    </Stack>
                    <Stack flexDirection={"row"} flexWrap={"wrap"}>
                      {timeHolder.map((item) => {
                        return (
                          <Stack
                            flexDirection={"column-reverse"}
                            width={37}
                            height={37}
                            ml={1}
                            alignItems={"baseline"}
                          >
                            <IconButton
                              variant="outlined"
                              size="large"
                              onClick={() => filter_selec_slot(item)}
                              sx={{ alignItems: "center" }}
                            >
                              <Clear color="warning" sx={{ fontSize: 18 }} />
                              <Typography
                                sx={{
                                  color: colorsApp.otraColotText,
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  alignSelf: "center",
                                }}
                              >
                                {item.starttime},
                              </Typography>
                            </IconButton>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>

                  <Stack
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={'space-between'}
                    mb={1}
                    p={1}
                  >
                    <Stack>
                      <Typography
                        variant="caption"
                        textAlign={"right"}
                        color={colorsApp.newTextClr}
                      >
                        عدد الساعات 
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        variant="caption"
                        textAlign={"left"}
                        color={colorsApp.otraGreenColor}
                      >
                        {timeHolder.length}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack alignItems={"center"}>
              {pikOrder &&
                <Box sx={{ width: "100%", padding: 1, mt: 3 }}>
                  <Stack
                    alignItems="center"
                    marginTop={3}
                    component={"form"}
                    sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
                    noValidate
                    bgcolor={'inherit'}
                  >
                    <Typography variant='body1' color={colorsApp.otraColotText}>بينات الحجز</Typography>
                    <Stack   flexDirection={'column'} component={'div'} dir='rtl' >
                      <Stack flexDirection={'row'}  justifyItems={'baseline'}>
                        <Input type="text" size='medium'  sx={{fontSize:22}}  required 
                                onChange={(e)=>setname(e.target.value)} placeholder='الاسم' autoFocus margin='1' fullWidth/>
                        <Input type="text" size='medium' sx={{fontSize:22}} required 
                                onChange={(e)=>setname2(e.target.value)} placeholder='الاسم الاخير'  margin='1' />
                      </Stack>
                      <Stack>
                         <Input type="email" size='medium' sx={{fontSize:22}} required 
                                onChange={(e)=>setgestEmaile(e.target.value)} placeholder='الايميل'  />
                      </Stack>
                      <Stack>
                          <Button
                          variant='contained'
                          size="medium"
                          color='success'
                          sx={{ mt: 2, color: colorsApp.otraMaintext }}
                          onClick={() =>mKpaymnet()}
                        >
                          تاكيد معلومات الضيف
                        </Button>
                        <Button
                          variant='outlined'
                          size="large"
                          color="info"
                          sx={{ mt: 2, color: colorsApp.rmadytext }}
                          onClick={() =>canselitOrder()}
                        >
                          تراجع
                        </Button>
                      </Stack>
                      
                    </Stack>
                    
                  </Stack>
                </Box>
                }
               {showButtonAccsepte&& <Box
                  sx={{
                    width: "321px",
                    mt: 3,
                    mr: 2,
                    ml: 2,
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={disable}
                    fullWidth
                    color="success"
                    size="large"
                    sx={{
                      mt: 1,
                    }}
                    onClick={() => submitOrder()}
                  >
                    تاكيد الحجز
                  </Button>
                  <Button
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    size="large"
                    sx={{ mt: 1 }}
                    onClick={() => navigate("/")}
                  >
                    الغاء
                  </Button>
                 
                </Box>}
              
            </Stack>
          </Box>
          <Box>
          {finallyMsg && (
              <Stack alignItems="center" mt={1} mb={2} width={width}>
                <Alert variant="outlined" severity='success'>
                  تهانينا لقد تم الدفع بنجاح وسوف تصل لك رساله بتفاصيل الحجز
                </Alert>
                <Button variant='contained' color='success' size='large' onClick={()=>navigate('/')}>
                  <Typography>
                    الصفحه الرئيسية
                  </Typography>
                </Button>
              </Stack>
            )}
          </Box>

      <Sheet isOpen={isOpen} onClose={() => setOpen(false)} 
        snapPoints={[600, 400, 100, 0]}
        initialSnap={1}
        onSnap={(snapIndex) =>
          console.log('> Current snap point index:', snapIndex)
        } >
        <Sheet.Container >
          <Sheet.Header />
          <Sheet.Content> 
          {showfrme&&<iframe src={paymentUrl}   width={"100%"} height={"360"}  min-width= {"600px"}  
                      title='child frame' frameborder="0" >
                      </iframe>
              }
            
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
          
          <WorktimePage />

          <FoterPage />
        </LayoutMainpage>
      </>
    );
}


export default Mainpicknek;