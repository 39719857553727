import { format } from 'date-fns';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  Divider,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Scrollbar } from '../../src/component/scrollbar';
import { SeverityPill } from '../../src/component/severity-pill';
import OrderService from '../sevice/request.service';
import { useEffect, useState } from 'react';
import MainTitle from '../component/otratext';
import { TopNavMaine } from '../layouts/top-nav-main';
import { colorsApp } from '../theme/colors';
import otra from '../../src/theme/images/otraico1.png'
import {FiLoader} from 'react-icons/fi'
import { collection,getDoc,getDocs,addDoc,updateDoc,deleteDoc,doc,where,query,onSnapshot,writeBatch } from "firebase/firestore";
import { db } from '../../src/config/firbase';
import { BorderAllRounded, Home } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
const statusMap = {
  pending: 'new',
  delivered: 'success',
  refunded: 'error'
};
let USERDATA
let userinfo="0554343"
export const OverviewLatestOrders = (props) => {
  const { orders = [], sx } = props;
  const[reauest,setrequest]=useState([])
  const[loading,setloading]=useState(false)
  const[userphone,setuserphone]=useState('')
  const {user}=useUserAuth()

  const location =useLocation()
  let navigate=useNavigate()
  const orderCollectionRef=collection(db,"orders")

useEffect(()=>{
  setloading(true)
  //GetOrders()
  //console.log("test seeeen=>",location.state.phone)
  getorderByval(userphone)

},[userphone])


useEffect(()=>{
       
  if(user){
    USERDATA={ 
    email:user.email===null?"":user.email,
    phone:user.phoneNumber===null?"":user.phoneNumber,
    userid:user.uid,
    accsesstoken:user.accessToken
}
if(USERDATA.email===""){
  userinfo=USERDATA.phone
  setuserphone(USERDATA.phone)
  console.log("tes nomber",userinfo)
}else{
  userinfo=USERDATA.email
  console.log("tes nomber",userinfo)
}
}  
},[])

const getorderByval=(val)=>{
  //console.log("start get slot from backend,==",val)
  const q = query(
    orderCollectionRef,
    //  where('owner', '==', currentUserId),
    where('phone', '==', val) // does not need index
    //  where('score', '<=', 100) // needs index  https://firebase.google.com/docs/firestore/query-data/indexing?authuser=1&hl=en
    // orderBy('score', 'asc'), // be aware of limitations: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
    // limit(1)
  );

 
  // const unsub = onSnapshot(q, (querySnapshot) => {
  const unsub = onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
      //console.log("start get REQUESt from backend,==",items)
    });
   setrequest(items)
   setloading(false)
  });
  return () => {
    unsub();
  };
 
  // eslint-disable-next-line



}
  return (
    <Box sx={{
      flexDirection:'row',
      alignItems:"center",
      height:"900px",
      backgroundColor:colorsApp.backgroundPage
    }}>
    <TopNavMaine/>
    <MainTitle width={"100%"} margin={"40px"}/>
    <Card sx={{backgroundColor:colorsApp.otrabrownhevy}}>
      <CardHeader color="red" title="سلة الحجوزات"  sx={{textAlign:'center',color:colorsApp.otrasubBannerTexts}}/>
      {loading?
      <Stack sx={{marginTop:5,marginLeft:1, marginRight:1,flexDirection:'column',alignItems:'center',alignContent:'center'}}>
         <CircularProgress size={33} value={'progress'} color="success" className='loading-icon'/>
         
      </Stack>
        
        :<Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: "410px" }}>
          <Table style={{backgroundColor:'transparent'}} >
            <TableHead  >
              <TableRow    >
                <TableCell style={{backgroundColor:colorsApp.otrabrown2color,color:colorsApp.black,borderWidth:1,alignItems:"center"}}  >
                  اسم الطاولة
                </TableCell>
                
                <TableCell   style={{backgroundColor:colorsApp.otrabrown2color,color:colorsApp.black,borderWidth:1,alignItems:"center"}} sortDirection="desc">
                  موعد  الحجز
                </TableCell>
                <TableCell  style={{backgroundColor:colorsApp.otrabrown2color,color:colorsApp.black,borderWidth:1,alignItems:"center"}}>
                  الحالة
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reauest.map((order) => {
                const createdAt = format(order.bokingdate, 'dd/MM/yyyy');

                return (
                  <TableRow
                    hover
                    key={order.id}
                  >
                    <TableCell>
                      {order.tabelname}
                    </TableCell>
                     
                    <TableCell>
                      {createdAt}
                    </TableCell>
                    <TableCell>
                      <SeverityPill color={statusMap[order.state]}>
                        {order.state}
                      </SeverityPill>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>}
       
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <Home />
            </SvgIcon>
          )}
          size="small"
          variant="text"
          onClick={()=>navigate("/")}
        >
          العودة للصقحة الرئيسيه
        </Button>
      </CardActions>
    </Card>
    </Box>
  );
};

OverviewLatestOrders.prototype = {
  orders: PropTypes.array,
  sx: PropTypes.object
};
