import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import {Box, Stack} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Images from '../theme/images/images';
import { colorsApp } from '../theme/colors';
  

export default function MainTitle(props) {
  return (
    <Box sx={{ flexGrow: 1,width:props.width, }} mt={props.margin} >
       <Stack  justifyItems={"center"} justifyContent={'center'} >
          <Typography   bgcolor={colorsApp.otraGreenColor}    color={colorsApp.otraMaintext} gutterBottom={true} sx={{fontFamily:"Cairo",fontStretch:'expanded',fontWeight:'bold',borderRadius:"10px",padding:1,width:props.width,textAlign:'center'  }}  fontSize={30}>
          عـــــــطـــــــــــرة
          </Typography>
          <Typography  component={'span'} align="center" fontStyle={"16px"} color={colorsApp.otrasubBannerText}  sx={{fontFamily:"Cairo",fontStretch:'expanded' ,position:"static",width:"100%"}}  fontSize={"16px"} mt={1} mb={1}>
           {props.subtitle?props.subtitle:"بستان عطرة"}
          </Typography>
          </Stack>
    </Box>
  );
}