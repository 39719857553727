import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {Box,Stack, Typography,Paper,Grid, Button, CircularProgress} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import {IoMdTimer} from 'react-icons/io'
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs'; 
import moment, { utc } from 'moment/moment';
import SlotService from '../../sevice/slots.service';
import { DateTime,Duration } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { collection,getDoc,getDocs,addDoc,updateDoc,deleteDoc,doc,where,query,onSnapshot,writeBatch } from "firebase/firestore";
import { db } from '../../config/firbase';
import { colorsApp } from '../../theme/colors';
import logo from '../../theme/images/otraico2.png'
import { SocialIcon } from 'react-social-icons'
import { date } from 'yup';
import useWindowDimensions from '../../hooks/useWindowDimensions ';

  
export default function BasicDateCalendar(props) {
  
const SlotsCollectionRef=collection(db,"slots")

  const[slots ,setslots]=React.useState([])
  const[slots2 ,setslots2]=React.useState([])
  const [checked, setChecked] = React.useState([0]);
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [intialCardInfo,setIntialCardInfo]=React.useState([])
  const[slot,setSlot]=React.useState([])
  const[Ds,setDs]=React.useState([])
  const[loding,setloding]=React.useState(false)
  const[lodingSlot,setlodingSlot]=React.useState(false)
  const[showslot,setShowslot]=React.useState(false)
  const[select,setSelect]=React.useState(false)
  let indx=0
  const { height, width } = useWindowDimensions()
  
React.useEffect(() =>{
 
     
  },[])

React.useEffect(()=>{ 
 //action claender
  handleChange1(value)
  ////console.log("change")
},[value])


React.useEffect(()=>{

//Run genrate slot time 
//GETSLOTSDATA()

////console.log("NEw slot ,",intialCardInfo)

},[intialCardInfo ])

const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange1=async(newValue)=>{
    
    ////console.log("value of show card",showslot)
    const slotDate=dayjs(newValue).format("YYYY-MM-DD")
    const slotDatenextDay=dayjs(newValue).add(1,"day").format("YYYY-MM-DD")

   // //console.log("E=", slotDate  );
    setShowslot(false)
    getSlotByval(slotDate)
    getSlotByval2(slotDatenextDay)
   
    
}


const getSlots=async()=>{
  const data =await SlotService.getslots()
  setslots(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
  setloding(false)
}



const getSlotByval=(val)=>{
  ////console.log("start get slot from backend,==",val)
  
  const q = query(
    SlotsCollectionRef,
    //  where('owner', '==', currentUserId),
    where('slotdate', '==', val) // does not need index
    //  where('score', '<=', 100) // needs index  https://firebase.google.com/docs/firestore/query-data/indexing?authuser=1&hl=en
    // orderBy('score', 'asc'), // be aware of limitations: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
    // limit(1)
  );

 
  // const unsub = onSnapshot(q, (querySnapshot) => {
  const unsub = onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
     // //console.log("start get slot from backend,==",items)
    });
   // console.log("start get slot from backend,==",items)
    //const timenow=moment(new Date()) 
   
    const result=items.filter((xitem)=>{
      const startTime =dayjs.unix(xitem.slottime).$H ;
      const timeNow = dayjs(new Date()).$H;
    //  console.log("from arra2y", startTime ," /// ",timeNow  )
      return    startTime >= (timeNow)
    })
    //console.log("start get slot from FELTER ,=xxx=",result)
   // setIntialCardInfo(result)
    setslots(result)
    setShowslot(true)
  });
  return () => {
    unsub();
  };
 
  // eslint-disable-next-line



}

 

const getSlotByval2=(val)=>{
 // console.log("start get slot from backend,== next day===>",val)
  
  const q = query(
    SlotsCollectionRef,
    //  where('owner', '==', currentUserId),
    where('slotdate', '==', val) // does not need index
    //  where('score', '<=', 100) // needs index  https://firebase.google.com/docs/firestore/query-data/indexing?authuser=1&hl=en
    // orderBy('score', 'asc'), // be aware of limitations: https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations
    // limit(1)
  );

 
  // const unsub = onSnapshot(q, (querySnapshot) => {
  const unsub = onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
     // //console.log("start get slot from backend,==",items)
    });
   // console.log("start get slot from backend,==",items)
    //const timenow=moment(new Date()) 
   
    const result=items.filter((xitem)=>{
      const startTime =dayjs.unix(xitem.slottime).$H ;
      const timeNow = dayjs(val).add(6,"hours").$H;
      //console.log("from arra2y",timeNow  )
      return    startTime <= (timeNow)
    })
    console.log("start get slot from FELTER ,=xxx=",result)
    //setIntialCardInfo(result)
    setslots2(result)
    setShowslot(true)
  });
  return () => {
    unsub();
  };
 
  // eslint-disable-next-line



}

const GETSLOTSDATA=()=>{
  // intialCardInfo.forEach((slot)=>{
  //  return //console.log("eacth sloot",slot)
  // })
   
    //console.log("eacth sloot",intialCardInfo[0])
      // const year=(moment().year())
      // const month=(moment().month())
      // const day=(moment().day())
      // const durationTime=Number(intialCardInfo[0].slotduration)
      // const startFrom=Number(intialCardInfo[0].starttime)
      // const endTo=Number(intialCardInfo[0].endtime)
      // const startFromMinutes=Number(intialCardInfo[0].startminuet)
      // const endToMinutes=Number(intialCardInfo[0].endminute)
      const MAinedate=dayjs(value).format("YYYY-MM-DD")
      const year=2024
      const month=3
      const day=22
      const durationTime=3600000
      const startFrom=1
      const endTo=2
      const startFromMinutes=0
      const endToMinutes=0
      const slot =  Duration.fromMillis(durationTime) // 30:00 minutes
      //console.log("slot==",slot)
    
    const period = {
        startTime: DateTime.local(year,month,day,startFrom,startFromMinutes),
        endTime: DateTime.local(year,month,day,endTo,endToMinutes)
    }
    
    var slots = [];
    var slotCount = Math.trunc((period.endTime.toMillis() - period.startTime.toMillis()) / slot.milliseconds);
    
    ////console.log("tets priod",period," start ",startFrom,"mint=",startFromMinutes,"end =",endTo,"mint=",endToMinutes )
    for (var i = 0; i < slotCount; i++) {
        slots[i] = {
            startTime: period.startTime.plus(i * slot.milliseconds),
            endTime: period.startTime.plus((i + 1) * slot.milliseconds),
            duration:durationTime,
            shift:"am",
            slotcount:slotCount,
            tabelid:"1237",
            slotdate:MAinedate
        }
        setTimeout(() => {
          //SlotService.addslots({...slots})
           //console.log("con1",slots)
        }, 2000);
        // if(i===slotCount-1){
        //   //console.log("X+++",slots)
        
        // }
      
    }

    var formattedSlots = slots.map(x => ({
        starttime: x.startTime.hour, 
        startminuet: x.startTime.minute,
        endtime: x.startTime.plus(slot).hour,
        endminute: x.startTime.plus(slot).minute,
        duration:x.duration,
        shift:x.shift,
        slotcount:x.slotcount,
        tabelid:x.tabelid,
        slotdate:x.slotdate
        //totalhours:x.totalhours
    }));
    //SlotService.addslots({...formattedSlots})
    
    const batch =writeBatch(db)
    
    formattedSlots.forEach((item,indx)=>{ 
      const nyRef=doc(db,"slots",`NYS1${uuidv4()}`)
      batch.set(nyRef,item)
      
    })
    batch.commit().then((res)=>{
      //console.log("was done ",res)
    }).catch(err=>console.log("errr==",err))

    // batch.set(nyRef,{...formattedSlots})
    // .commit().then(()=>{
    //   //console.log("was done ")
    // }).catch(err=>//console.log("errr==",err))
   
   
    //console.log( "formattedSlots",formattedSlots);
  

}

const bookingTime=(val)=>{
    const dataOrder={
    bokingdate:dayjs(value).format("YYYY-MM-DD"),
    bokingstarttime:`${val.starttime}:${val.startminuet}`,
    bokingendtime:`${val.endtime}:${val.endminute}`,
    slot:val
    }
   props.dataHolder(dataOrder)
}

const BookingInfo=()=>{
 
}
 
 


  return (
    <Box sx={{width:width,display:'block',alignItems:'center',flexDirection:'column',margin:0}} >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack >
            <DateCalendar
              defaultValue={dayjs(new Date())}
              onChange={(newValue) =>setValue(newValue)}
              disablePast={true}
              value={value}
               sx={{alignSelf:'center',boxShadow:'revert'}}
            />
          </Stack>      
        </LocalizationProvider>
        <Stack  flexDirection={"column"} alignItems={"center"} display={'flex'} >
         {/* <Button variant='text' onClick={()=>GETSLOTSDATA()}>creat day</Button> */}
          {showslot&&<Stack   sx={{backgroundColor:"transparent",alignItems:'center'}}>
            {slots.sort((a,b) => (a.slottime > b.slottime) ? 1 : ((b.slottime > a.slottime) ? -1 : 0)).map((x,index)=>{
              return(
                <Stack key={index} elevation={3} sx={{backgroundColor:"#6b625b",alignItems:'center', padding:1,marginTop:1,boxShadow:'initial',width:240}}  >
                  <Button onClick={()=>bookingTime(x,index)} variant="text"  sx={{color:"white"}}> من {x.starttime}:{x.startminuet}  الى {x.endtime}:{x.endminute} {x.starttime> 12 ?"(مساء)":"(ليلا)"}</Button>
                </Stack>
              )
              })
            }
          </Stack>
          }
          {slots.length<1&&
          <CircularProgress size={33}  color='success' />
          }
        </Stack>

        <Stack  flexDirection={"column"} alignItems={"center"} display={'flex'} >
         {/* <Button variant='text' onClick={()=>GETSLOTSDATA()}>creat day</Button> */}
          {showslot?<Stack   sx={{backgroundColor:"transparent",alignItems:'center'}}>
            {slots2.sort((a,b) => (a.slottime > b.slottime) ? 1 : ((b.slottime > a.slottime) ? -1 : 0)).map((x,index)=>{
              return(
                <Stack key={index} elevation={3} sx={{backgroundColor:"#6b625b",alignItems:'center', padding:1,marginTop:1,boxShadow:'initial',width:240}}  >
                  <Button onClick={()=>bookingTime(x,index)} variant="text"  sx={{color:"white"}}> من {x.starttime}:{x.startminuet}  الى {x.endtime}:{x.endminute} {x.starttime> 12 ?"(مساء)":"(ليلا)"}</Button>
                </Stack>
              )
              })
            }
          </Stack>:
            <Stack alignItems={"center"}>
              <Typography variant="h4" color={'ActiveCaption'}>لاتوجد اوقات مجدوله</Typography>
            </Stack>
          }
         
        </Stack>
        
        
        
    

    </Box>
    
  );
}