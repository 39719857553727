import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input'
import { useUserAuth } from '../context/UserAuthContext';


import { CgScreen } from 'react-icons/cg'
import { getAuth, RecaptchaVerifier, signInAnonymously, signInWithPhoneNumber } from "firebase/auth";
import userAuthContext from '../context/UserAuthContext'
//import OTPInput from 'react-otp-input';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { auth, db } from '../config/firbase';
import { FiLoader, FiPhone, FiUser } from 'react-icons/fi';
import CheckIcon from '@mui/icons-material/Check';
import MainTitle2 from '../component/otratext2';
import MainTitle from '../component/otratext';
import { colorsApp } from '../theme/colors';
import logo  from '../../src/theme/images/33.png'
import { TopNavMaine } from '../layouts/top-nav-main';
import { right } from '@cloudinary/url-gen/qualifiers/textAlignment';
import useWindowDimensions from '../hooks/useWindowDimensions ';
import { doc, setDoc } from 'firebase/firestore';



import { styled } from '@mui/system';

const StyleButton=styled(Button)(()=>({
  backgroundColor:colorsApp.otraGreenColor,
  textAlign:'center',
  color:colorsApp.otraMaintext,
  marginTop:22
}))

const LoginPage = () => {
  const { height, width } = useWindowDimensions();
  const [method, setMethod] = useState('phoneNumber');
  const [showErorr, setshowErorr] = useState(false);
  const [helperText, sethelperText] = useState("thanks use text")
  const [value, setValue] = useState("")
  const [email, setemaile] = useState("")
  const [password, setpassworde] = useState("")
  const [Erorr, setError] = useState("")
  const [phase, setphase] = useState('regester')
  const [phone, setphone] = useState("")
  const[confirmObj,setConfirmObj]=useState("")
  //const[otp,setotp]=useState('')
  const [loading, setloding] = useState(false)
  const [msg, setmsg] = useState('')
  const [showMsg, setshhowMsg] = useState(false)
  const[name,setname]=useState("")
const[desablebutton,setdissablebutton]=useState(false)

  const [OTP, setOTP] = useState("");
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const[siginmode,setsiginmode]=useState(false)
 



  let TYPEERORR = ''
  const { logIn, googleSignIn, setUser,Anonymously,setUpRecaptchaVerifier } = useUserAuth();
  let navigate = useNavigate()
  

  useEffect(() => {
    if (showErorr) {
      setTimeout(() => {
        setshowErorr(false)
      }, 6000);
    }
  }, [showErorr])


  useEffect(() => {

    setTimeout(() => {
      setshhowMsg(false)
    }, 3000);

  }, [showMsg])

 



  const handelPhaseChange = (val) => {
    setphase(val)
    if (val === "complete") {
      setloding(true)
      // navigate("/landpage")
      navigate("/")
    }
  }

  const handleChange = (newPhone) => {

    //console.log(newPhone)
    setphone(newPhone)
  }
  const handleMethodChange = useCallback(
    (event, value) => {
      setMethod(value);
    },
    []
  );

  function onCapchVerview() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //  onSingupInSubmit();
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          console.log("expired  Verccation")
        },
        auth  
      });
    }

  }

  

  // const onSingupInSubmit = async() => {
  //   setloding(true)
  //   onCapchVerview()
  //   const phoneNumber = phone;
  //   let  appVerifier = await window.recaptchaVerifier;
  //   console.log(phoneNumber)
  //  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  //     .then(async(confirmationResult) => {
  //       // SMS sent. Prompt user to type the code from the message, then sign the
  //       // user in with confirmationResult.confirm(code).
  //       window.confirmationResult = confirmationResult;
        
  //       setloding(false)
  //       TYPEERORR = "success"
  //       setmsg(`تم ارسال كود التحقق على ${phone}`)
  //       setshhowMsg(true)
  //       handelPhaseChange("authcode")
  //       // ...
  //     }).catch((error) => {
  //       console.log("Erorr from singup ++", error)
  //       setloding(false)
  //       setshhowMsg(true)
  //       setError("خطاء في بيانات الدخول")
  //       TYPEERORR = "error"
  //       setmsg(`رقم الجوال غير صحيح الرجاء التاكد`)
       

  //     });
     
  // }
  const SIGHNWITHPHONE=async()=>{
    const phoneNumber = phone;
    if (phoneNumber ==="" || phoneNumber==undefined){
      return setshhowMsg(true)
      ,setError("خطاء في بيانات الدخول")
      ,TYPEERORR = "error"
      ,setmsg(`رقم الجوال غير صحيح الرجاء التاكد`)
    }

    try{
      const response=await setUpRecaptchaVerifier(phoneNumber)
      console.log('responce',response)
      setConfirmObj(response)
      setloding(false)
      TYPEERORR = "success"
      setmsg(`تم ارسال كود التحقق على ${phone}`)
      setshhowMsg(true)
      handelPhaseChange("authcode")
    }catch(err){
      console.log("Erorr new PhoneSign in",err)
      setshhowMsg(true)
      setError("خطاء في بيانات الدخول")
      TYPEERORR = "error"
      setmsg(`رقم الجوال غير صحيح الرجاء التاكد`)
    }
  }
  
  // function getauthbyOtp() {
  //   const code = OTP;
  //   //console.log("user OOtb ", code)

  //   window.confirmationResult.confirm(code).then(async(result) => {
  //     // User signed in successfully.
  //     const user = result.user;
         

  //     console.log("user sign by phone successfully ", user)
  //     setUser(user)
  //     handelPhaseChange("complete")
  //     // ...
  //   }).catch((error) => {
  //     //console.log("user sign by  code phone Errr=> ", error)
  //     setError("خطاء بكود التحقق")
  //     // User couldn't sign in (bad verification code?)
  //     // ...
  //   });

  // }
  const outhOtp=()=>{
    const code = OTP;
    confirmObj.confirm(code).then((result) => {
      // User signed in successfully.
      const user = result.user;
      console.log("user sign by phone successfully ", user)
      setUser(user)
      handelPhaseChange("complete")
      // ...
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...
    });
  }

  const handleChangeSubmit = async (e) => {

    e.preventDefault();
    //console.log("test sing in  ", email, password)
    setError("");
    if (email === "" || password === "") {
      setshowErorr("email or passord is empty ?")
    }
    try {
      await logIn(email, password);
      navigate("/");
    } catch (err) {
      setError(err.message);
      setshowErorr("خطاء في بينات الدخول ")
    }

  }

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/");
    } catch (error) {
      //console.log(error.message);
    }
  };


  const userRedirctAsgest = async(value) => {
    //setloding(true)
    try{
      Anonymously().then((res)=>{
        //console.log("drom Anonymously ",res)
        navigate("/landpage")
      })
       
    }catch(err){
      //console.log("MaineErorr from Anonymously ",err)
    }
   
 }

  const userRedirct = (value) => {
     setsiginmode(true)
     setphase("regester")
  }


  //   const handleSkip = useCallback(
  //     () => {
  //       auth.skip();
  //       router.push('/');
  //     },
  //     [auth, router]
  //   );

  return (
    <>
      {showMsg && <Alert sx={{ mt: 2 }} icon={<CheckIcon fontSize="inherit" />} severity={TYPEERORR}>
        {msg}
      </Alert>}

      <Box
        sx={{
          backgroundColor: colorsApp.backgroundPage,
          height:height,
          width:width,
          padding:1
        }}
        className="app-page"
      >
        <TopNavMaine />
        <Stack sx={{ alignItems: 'center' }}>
          <MainTitle width={"340px"} margin={5} />
        </Stack>

        <Stack mb={"20px"} width={"100%"}>
          <MainTitle2 width={"100%"} text={"بستان عطرة"} />
        </Stack>
        {/* {siginmode? <Stack sx={{alignItems:'center',flexDirection:'column',width:'100%'}}>
          <img src={logo} width={"100px"} height={"100px"} alt='otra logo'/>
          <Stack flexDirection={'column'} justifyContent={'space-around'} alignItems={'center'}>
            <Button variant="contained" size="large" color="primary" sx={{mt:2}}
                    onClick={()=>userRedirctAsgest("gest")} endIcon={<FiUser size={18} color={colorsApp.white} />}><Typography>تسجيل الدخول كضيف</Typography></Button>
            <Button variant="contained" size="large" color="primary" sx={{mt:2}}
                    onClick={()=>userRedirct("regester")} endIcon={<FiPhone size={18} color={colorsApp.white} />}><Typography>تسجيل الدخول برقم الجوال</Typography></Button>
          </Stack>

        </Stack>: */}
         <Box sx={{ alignItems: "center", justifyContent: "center", flexDirection: 'column', backgroundColor: "#423D39" , mt:4, borderTopRightRadius: 40, borderTopLeftRadius: 40,position:'absolute',bottom:1,width:'100%',padding:2  }}>
        
          {/* <Stack sx={{ backgroundColor: "#423D39" }}  > */}
            {phase === "regester" &&
               
                <FormControl sx={{justifyContent:"center",width:"100%",mt:1,alignItems:'center'}}  >
                  <Stack alignItems={'center'} sx={{width:'100%',backgroundColor:'transparent'}}>
                    <FormLabel sx={{ color: colorsApp.otrabrown1color, mb: 1, mt: 1, fontFamily: 'Cairo', fontSize: 18,textAlign:'right' }}>رقم الجوال</FormLabel>
                    <MuiTelInput 
                    inputProps={{ style: { fontFamily: 'Cairo', color:colorsApp.otrabrown2color,}}}
                    margin='normal'
                    required={true}
                    autoFocus={true}
                    inputMode='tel'
                    fullWidth
                      sx={{ width: "255px", borderRadius: "3px",borderColor:colorsApp.otraColotText,borderWidth:1, fontSize: 18,}}
                      defaultCountry='SA' onlyCountries={["SA"]} value={phone} onChange={handleChange} />
                  </Stack>
                  
                  
                    <Button
                    id='sign-in-button'
                    color='primary'
                    size="large"
                    sx={{ mt: 4,}}
                    type="submit"
                    disabled={desablebutton}
                    variant='contained'
                    onClick={() => SIGHNWITHPHONE()}
                  >تسجيل الدخول
                  </Button>
                 
                </FormControl>
                
               
            }
            {phase === "authcode" &&
              <Stack   sx={{width:"100%",flexDirection:'column',alignItems:'center',paddingTop:1,paddingBottom:2,justifyContent:'center',borderTopColor:'black'}}  >
                <FormLabel sx={{ mt: 4, mb: 2, alignContent: 'center', textAlign: 'center', color: colorsApp.otrabrown2color, fontSize: 20 }}>كود التحقق المرسل على الجول</FormLabel>
                <OTPInput value={OTP} onChange={setOTP} OTPLength={6} otpType="number" disabled={false}    />
                <Stack sx={{alignItems:'center'  }}>
                  <Typography variant="body1"  fontSize={18} mt={3} mb={1} color={"white"} sx={{direction:"ltr"}} >تم ارسال رمز التحقق على {phone}</Typography>
                </Stack>
                
                <Button
                fullWidth
                  color="success"
                  size='large'
                  sx={{ mt: 3,mb:8,fontSize:20 }}
                  type="submit"
                  variant="contained"
                  onClick={() => outhOtp()}
                  
                >
                  تحقق من الرمز
                </Button>
              </Stack>
            }
          {/* </Stack> */}

          {phase === "regester" &&
            <Stack
              spacing={1} width={"100%"}
              sx={{ flexDirection: 'row', mt: 3, mb: 1, padding: 1, justifyContent: 'center', backgroundColor: "transparent" }}
            >
              <Typography color={colorsApp.otrabrown1color} variant="body2" textAlign={'center'} sx={{ fontFamily: 'Cairo', fontSize: 18, }}>
                <Link component={'button'} variant="body1" sx={{ color: colorsApp.otrabrown1color }} onClick={() => navigate("/login")}>للاطلاع على الشروط والاحكام ؟</Link>

              </Typography>
            </Stack>}

          
           
          {showErorr&&
            <Stack   sx={{ alignItems: 'center', backgroundColor: "transparent", mt:2, padding: 1 }}>
              <Typography variant="body1" fontSize={22} color={colorsApp.error}>{msg}</Typography>
            </Stack>}
          {loading && 
             <Stack   sx={{ alignItems: 'center', backgroundColor: "transparent", mt:2, padding: 1 }}>
              <FiLoader size={40} className="loading-icon" />
              </Stack>}
              <div id='recaptcha-container' />

          {/* <div>
          
            <button id='sign-in-button' hidden={false} >rech</button>
            <div id="recaptcha_widget" style={{display:'none'}}></div>
          </div> */}





        </Box>


      </Box>

    </>
  );
};
export default LoginPage;
// Page.getLayout = (page) => (
//   <AuthLayout>
//     {page}
//   </AuthLayout>
// );

// export default Page;


