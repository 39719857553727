import React, { useCallback, useState } from 'react'
import {
    Alert,Container,
    Box,Grid,
    Button,
    FormHelperText,
    Link,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    styled
  } from '@mui/material';
import PrimarySearchAppBar from '../component/Navbar';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';
import { TopNav } from '../layouts/top-nav';
import { OverviewLatestOrders } from '../overview/overview-latest-orders';
import { TabeleDetails } from './regestrTabel';
import { TabelCard } from '../component/card';
import TabelService from "../../src/sevice/order.service"
import { useEffect } from 'react';
import TabelShow from './tabelshow';
import { TabeleDetailsUpdate } from './updateTabe;';
import { colorsApp } from '../theme/colors';
import ProtectedRoute from '../component/protectRoute';

let DATAHOLDER
const ControlTabel = () => {
  let navigate =useNavigate()
  const[screen,setScreen]=useState(false)
   
const SIDE_NAV_WIDTH = 0;

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

const ChangeCompnent=(val,data)=>{
  //console.log("step 2 ===","val is",val,"data=",data)
   DATAHOLDER=data
  setScreen(true)
}
    

 

 
    return (
      <>
      <Box   sx={{ flexGrow: 1,backgroundColor:colorsApp.backgroundPage }}>
         
           
         <Box padding={2}>
          {!screen?<TabeleDetails />:
           <TabeleDetailsUpdate data={DATAHOLDER} rejectUpdate={()=>setScreen(!screen)}/> }
         </Box>
         <Box padding={2} >
           <TabelShow changeScreenValue={(val,data)=>ChangeCompnent(val,data)}/>
           
         </Box>
          
    </Box>
   
      </>
      
        
        
    )
}
export default ControlTabel;