import React from 'react'
import { Stack,Box } from "@mui/material"
import { SocialIcon } from "react-social-icons"
import logo from "../../src/theme/images/otratranspertLogo.png"
import { colorsApp } from '../theme/colors'
import foterlogo from '../theme/images/fotter.png'
const FoterPage=(props)=>{
return(
    <Box sx={{width:"100%",backgroundImage:`${foterlogo}`,resize:'block',borderTopStyle:'groove'}} >
        <footer className='App-foter' style={{backgroundColor:colorsApp.transparent,width:"100%",alignItems:'center'}}>
          <Stack height={100} width={"100%"} sx={{backgroundColor:colorsApp.backgrounheader,mt:0,alignItems:'center',flexDirection:'column'}}>
            {/* <img src={logo}  width={40} height={40} alt='logo' style={{borderRadius:50,marginTop:1,backgroundColor:colorsApp.otraColotText,}}/>  */}
            <Stack flexDirection={'row'} sx={{justifyContent:"space-between",m:2,width:320}}>
              <SocialIcon url="https://x.com/etruh_cafe?s=21&t=L7AmEn4ROXW6LHZfzdpAUg"  bgColor={colorsApp.backgrounheader}  onClick={()=>console.log("clike")}  />
              <SocialIcon url="https://www.tiktok.com/@etruh_cafe?_t=8kXaHQthSIz&_r=1" bgColor={colorsApp.backgrounheader} onClick={()=>console.log("clike")}  />
              <SocialIcon url="https://www.instagram.com/etruh_cafe?igsh=bHE0cHFiazgweTdk&utm_source=qr"  bgColor={colorsApp.backgrounheader} onClick={()=>console.log("clike")}  />
              <SocialIcon url="https://linkedin.com/in/couetilc"  bgColor={colorsApp.backgrounheader} onClick={()=>console.log("clike")}  />

            </Stack>
            
          </Stack> 
          
         </footer>
    </Box>
)
}

export default FoterPage;

